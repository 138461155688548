import { Component } from 'react';
import {Layout, Navigation, Header, Drawer, Content, IconButton, Menu, MenuItem } from 'react-mdl';
import Main from './components/main';
import { Link } from 'react-router-dom';

import { i18n } from "./translate/i18n";

import Colors from './utils/colors.js';

/*******************************************************************************************/
/*******************************************************************************************/
/*QUALQUER ÍCONE QUE VENHA DE OUTRA FONTE DEVERÁ SER IMPORTADO MANUALMENTE, CONFORME ABAIXO*/

import logoIcon from './components/images/LOGO.svg';
import textIcon from './components/images/TEXTO.svg';

const I18N_STORAGE_KEY = 'i18nextLng';

class App extends Component {
  hideToggle()
  {
    var selectorId = document.querySelector('.mdl-layout');
    selectorId.MaterialLayout.toggleDrawer();
  }

  render() {
    const handleSelectChange = event => {
      localStorage.setItem(I18N_STORAGE_KEY, event.target.value)
      window.location.reload();
    }

    return(
      <div class="mdl-layout mdl-js-layout mdl-layout--fixed-header" style={{fontFamily: 'DIN Alternate'}}>
      <Layout fixedHeader>

            <Header  title=" " style={{background: Colors.padrao}} id="demo-menu-lower-left">
              <img src={textIcon} style={{width: '170px', filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(261deg) brightness(103%) contrast(103%)"}}/>
              <img src={logoIcon} style={{width: '40px', filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(261deg) brightness(103%) contrast(103%)"}}/>
              
              <Navigation>
              <IconButton name="more_vert" id="demo-menu-lower-right"/>
              <Menu target="demo-menu-lower-right" align="right" ripple>
                  <MenuItem disabled>
                  <option>{i18n.t('header.idiom')}</option>
                  </MenuItem>
                  <MenuItem>
                    <option value="en-US" onClick={handleSelectChange}>English</option>
                  </MenuItem>
                  <MenuItem>
                    <option value="pt-BR" onClick={handleSelectChange}>Português</option>
                  </MenuItem>
              </Menu>
            </Navigation>
        </Header>

        <Drawer>
          <Navigation className="drawer-style">
            <Link to="/" onClick={() => this.hideToggle()}>{i18n.t('drawer.main')}</Link>
            <Link to="/products" onClick={() => this.hideToggle()}>{i18n.t('drawer.products')}</Link>
            <Link to="/projects" onClick={() => this.hideToggle()}>{i18n.t('drawer.projects')}</Link>
            <Link to="/history" onClick={() => this.hideToggle()}>{i18n.t('drawer.history')}</Link>
            <Link to="/contact" onClick={() => this.hideToggle()}>{i18n.t('drawer.contact')}</Link>
          </Navigation>
        </Drawer>

        <Content>
            <div className="page-content" />
            <Main/>
        </Content>

      </Layout>
  </div>
  );
  }
}

export default App;