import React from "react";
import { Component } from "react";
import { i18n } from "../translate/i18n";
import { Link } from 'react-router-dom';

import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBView, MDBBtn } from "mdbreact";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem} from "mdbreact";

import Colors from '../utils/colors.js';

/* import FeatherIcon from 'react-native-vector-icons/dist/Feather'; */
import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome';
import MaterialCommunityIconsIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
/* import IoniconsIcon from 'react-native-vector-icons/dist/Ionicons'; */

/*import product1 from './images/home/products/smart.jpeg'; */
import product1 from './images/home/products/smart.png';
import product2 from './images/home/products/app.jpg';
import product3 from './images/home/products/int 1p.jpg';
import product4 from './images/home/products/cortina.jpg';
import product5 from './images/home/products/irrigacao.jpg';
import product6 from './images/home/products/chuveiro.jpg';
import product7 from './images/home/products/termometro.jpg';

import project1 from './images/home/projects/1.png';
import project2 from './images/home/projects/2.png';
import project3 from './images/home/projects/3.png';
import project4 from './images/home/projects/4.png';
import project5 from './images/home/projects/5.jpg';

import history1 from './images/home/history/IoT.jpg';
import history2 from './images/home/history/evolution.jpg';
import history3 from './images/home/history/M 4.jpg';
import history4 from './images/home/history/4G 2.jpg';
import history5 from './images/home/history/customer.png';


const scrollToTop = () =>{
  window.scrollTo({
    top: 0, 
    behavior: 'smooth' /* you can also use 'auto' behaviour in place of 'smooth' */
  });
  //alert("HOME");
};


class Home extends Component
{
    render()
    {
      scrollToTop();
        return(
            <MDBCard className="my-0 px-0 pb-5">
            <MDBCardBody>
              <h2 className="h1-responsive font-weight-bold text-center pt-0 mb-3 mt-3 my-3">
          {i18n.t('home.title0')}
              </h2>
              <br/>
              <MDBRow>
                <MDBCol lg="4.5">
              
<MDBCarousel
activeItem={1}
length={7}
showControls={true}
showIndicators={true}
className="rounded z-depth-2 mb-lg-0 mb-4" hover waves
style={{width: '100%', margin: 'auto'}}
>
  <MDBCarouselInner>


    <MDBCarouselItem itemId="1">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product1}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod1')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>

    <MDBCarouselItem itemId="2">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product2}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod2')}
        </h3>
      </MDBCarouselCaption>
    </MDBCarouselItem>

    <MDBCarouselItem itemId="3">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product3}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod3')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
    <MDBCarouselItem itemId="4">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product4}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod4')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
    <MDBCarouselItem itemId="5">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product5}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod5')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
    <MDBCarouselItem itemId="6">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product6}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod6')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
    <MDBCarouselItem itemId="7">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={product7}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.prod7')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

  </MDBCarouselInner>
</MDBCarousel>

                </MDBCol>
                <MDBCol lg="7">
                  <a style={{color: Colors.padrao}} >
                    <h6 className="font-weight-bold mb-3">
          <MaterialCommunityIconsIcon name="lightbulb-on-outline" size={17} style={{color: Colors.padrao}} />
          {i18n.t('home.type1')}
                    </h6>
                  </a>
                  <h3 className="font-weight-bold mb-3 p-0">
                    <strong>
              {i18n.t('home.title1')}
                    </strong>
                  </h3>
                  <p>
              {i18n.t('home.text1')}
                  </p>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text11')}<br/></li>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text12')}<br/><br/></li>

                  <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={() => scrollToTop()}>
              <Link to="/products" style={{color: Colors.branco}}>{i18n.t('home.button1')}</Link>
                  </MDBBtn>

                </MDBCol>
              </MDBRow>
              <hr className="my-5" />
              <MDBRow>
                <MDBCol lg="7">
                  <a style={{color: Colors.padrao}} >
                    <h6 className="font-weight-bold mb-3">
{/*          <MaterialCommunityIconsIcon name="factory" size={17} style={{color: Colors.padrao}} /> */}
{/*          <FeatherIcon name="cpu" size={17} style={{color: Colors.padrao}} /> */}
{/*          <IoniconsIcon name="hardware-chip-outline" size={17} style={{color: Colors.padrao}} /> */}
          <FontAwesomeIcon name="gears" size={17} style={{color: Colors.padrao}} />
          {i18n.t('home.type2')}
                    </h6>
                  </a>
                  <h3 className="font-weight-bold mb-3 p-0">
                    <strong>
              {i18n.t('home.title2')}
                    </strong>
                  </h3>
                  <p>
              {i18n.t('home.text2')}
                  </p>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text21')}<br/></li>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text22')}<br/><br/></li>
                  
                  <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={() => scrollToTop()}>
                <Link to="/projects" style={{color: Colors.branco}}>{i18n.t('home.button2')}</Link>
                  </MDBBtn>

                </MDBCol>
                <MDBCol lg="4.5">

              
<MDBCarousel
activeItem={1}
length={5}
showControls={true}
showIndicators={true}
className="rounded z-depth-2 mb-lg-0 mb-4" hover waves
style={{width: '100%', margin: 'auto'}}
>
  <MDBCarouselInner>

  <MDBCarouselItem itemId="1">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={project1}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.proj1')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

    <MDBCarouselItem itemId="2">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={project2}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.proj2')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
    <MDBCarouselItem itemId="3">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={project3}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.proj3')}
        </h3>
      </MDBCarouselCaption>
    </MDBCarouselItem>

    <MDBCarouselItem itemId="4">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={project4}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.proj4')}
        </h3>
      </MDBCarouselCaption>
    </MDBCarouselItem>

    <MDBCarouselItem itemId="5">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={project5}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.proj5')}
        </h3>
      </MDBCarouselCaption>
    </MDBCarouselItem>


  </MDBCarouselInner>
</MDBCarousel>

                </MDBCol>
              </MDBRow>
              <hr className="my-5" />
              <MDBRow>
                <MDBCol lg="4.5">

              
<MDBCarousel
activeItem={1}
length={5}
showControls={true}
showIndicators={true}
background-color="yellow"
className="rounded z-depth-2 mb-lg-0 mb-4" hover waves
style={{width: '100%', margin: 'auto'}}
>
  <MDBCarouselInner>

  <MDBCarouselItem itemId="1">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={history1}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.hist1')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

    <MDBCarouselItem itemId="2">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={history2}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.hist2')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
  <MDBCarouselItem itemId="3">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={history3}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.hist3')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

    <MDBCarouselItem itemId="4">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={history4}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.hist4')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

    <MDBCarouselItem itemId="5">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={history5}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('home.hist5')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

  </MDBCarouselInner>
</MDBCarousel>

                </MDBCol>
                <MDBCol lg="7">
                  <a style={{color: Colors.padrao}} >
                    <h6 className="font-weight-bold mb-3">
          <MaterialCommunityIconsIcon name="head-lightbulb" size={18} style={{color: Colors.padrao}} /> 
{/*          <FontAwesomeIcon name="arrows" size={17} style={{color: Colors.padrao}} /> */}
          {i18n.t('home.type3')}
                    </h6>
                  </a>
                  <h3 className="font-weight-bold mb-3 p-0">
                    <strong>
              {i18n.t('home.title3')}
                    </strong>
                  </h3>
                  <p>
              {i18n.t('home.text3')}
                  </p>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text31')}<br/></li>
                  <li className="p-2 text-left align-content-stretch">{i18n.t('home.text32')}<br/><br/></li>
                  
                  <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={() => scrollToTop()}>
                <Link to="/history" style={{color: Colors.branco}}>{i18n.t('home.button3')}</Link>
                  </MDBBtn>
                  
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        );
    }
}

export default Home;