import { Component } from "react";

import { i18n } from "./../translate/i18n";

class Policy extends Component {
    render() {
      return (
        <div>
          <section className="my-0 px-2 pb-1">
            <h1 className="p-4 h1-responsive font-weight-bold text-center pt-0 mb-3 mt-3 my-3">
              {i18n.t('policy.title0')}
            </h1>
            <h6>
              {i18n.t('policy.text00')}<br/>
              {i18n.t('policy.text01')}<br/>
              {i18n.t('policy.text02')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text03')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text04')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text05')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text06')}<br/></li>
            </h6>

            <h4>{i18n.t('policy.title1')}</h4>
            <h6>
              {i18n.t('policy.text10')}<br/>
            </h6>
            
            <h4>{i18n.t('policy.title2')}</h4>
            <h6>
              {i18n.t('policy.text20')}<br/>
            </h6>

            <h5>
              {i18n.t('policy.option21')}<br/>
            </h5>
            <h6>
              {i18n.t('policy.text21')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text22')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text23')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text24')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text25')}<br/></li>
              {i18n.t('policy.text26')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text27')}<br/></li>
              {i18n.t('policy.text28')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text29')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text210')}<br/></li>
            </h6>
            
            <h5>
              {i18n.t('policy.option22')}<br/>
            </h5>
            <h6>
              {i18n.t('policy.text211')}<br/>
            </h6>
            
            <h5>
              {i18n.t('policy.option23')}<br/>
            </h5>
            <h6>
              {i18n.t('policy.text212')}<br/>
              {i18n.t('policy.text213')}<br/>
            </h6>

            <h4>{i18n.t('policy.title3')}</h4>
            <h6>
              {i18n.t('policy.text214')}<br/>
            </h6>

            <h4>{i18n.t('policy.title4')}</h4>
            <h6>
              {i18n.t('policy.text215')}<br/>
              {i18n.t('policy.text216')}<br/>
            </h6>

            <h4>{i18n.t('policy.title5')}</h4>
            <h6>
              {i18n.t('policy.text217')}<br/>
              {i18n.t('policy.text218')}<br/>
            </h6>

            <h4>{i18n.t('policy.title6')}</h4>
            <h6>
              {i18n.t('policy.text219')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text220')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text221')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text222')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text223')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text224')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text225')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text226')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text227')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text228')}<br/></li>
              {i18n.t('policy.text229')}<br/>
            </h6>
            
            <h5>
              {i18n.t('policy.option61')}<br/>
            </h5>
            <h6>
              {i18n.t('policy.text230')}<br/>
            </h6>

            <h4>{i18n.t('policy.title7')}</h4>
            <h6>
              {i18n.t('policy.text231')}<br/>
              {i18n.t('policy.text232')}<br/>
              {i18n.t('policy.text233')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text234')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text235')}<br/></li>
              {i18n.t('policy.text236')}<br/>
              {i18n.t('policy.text237')}<br/>
            </h6>
            
            <h4>{i18n.t('policy.title8')}</h4>
            <h6>
              {i18n.t('policy.text238')}<br/>
            </h6>

            <h4>{i18n.t('policy.title9')}</h4>
            <h6>
              {i18n.t('policy.text239')}<br/>
              {i18n.t('policy.text240')}<br/>
              {i18n.t('policy.text241')}<br/>
            </h6>

            <h4>{i18n.t('policy.title10')}</h4>
            <h6>
              {i18n.t('policy.text242')}<br/>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text243')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text244')}<br/></li>
              <li className="p-2 text-left align-content-stretch">{i18n.t('policy.text245')}<br/></li>
            </h6>
            
          </section>
        </div>
      );
    }
  }
  
  export default Policy;