import React from 'react';
import { Component } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBCardBody } from "mdbreact";
import { MDBBtn, MDBView, MDBMask } from "mdbreact";

import { i18n } from "../translate/i18n";

import YoutubeEmbed from "../utils/youtube";

import Colors from '../utils/colors.js';

import Modal1 from './modais/modal1';
import Modal2 from './modais/modal2';
import Modal3 from './modais/modal3';
import Modal4 from './modais/modal4';
import Modal5 from './modais/modal5';
import Modal6 from './modais/modal6';
import Modal7 from './modais/modal7';
import Modal8 from './modais/modal8';

import image1 from './images/products/int 1p.jpg';
import image2 from './images/products/irrigation.jpg';
import image3 from './images/products/hub.jpg';
import image4 from './images/products/shower.jpg';
import image5 from './images/products/curtain.jpg';
import image6 from './images/products/pool.jpg';
import image7 from './images/products/thermometer.jpg';
import image8 from './images/products/lights.jpg';

import logoIcon from './images/LOGO.svg';

import EntypoIcon from 'react-native-vector-icons/dist/Entypo';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome';
import FontAwesome5BrandsIcon from 'react-native-vector-icons/dist/FontAwesome5';
import FoundationIcon from 'react-native-vector-icons/dist/Foundation';
import MaterialCommunityIconsIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
/*******************************************************************************************/
/*******************************************************************************************/
{/*
import AntDesignIcon from 'react-native-vector-icons/dist/AntDesign';
import FontistoIcon from 'react-native-vector-icons/dist/Fontisto';
import IoniconsIcon from 'react-native-vector-icons/dist/Ionicons';
import MaterialIconsIcon from 'react-native-vector-icons/dist/MaterialIcons';
*/}

class Products extends Component
{
  state = {
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    modal8: false
  }
  
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

    render()
    {
        return(
          <section className="my-0 px-7 pb-5">
              
              <h2 className="text-justify p-4 h1-responsive font-weight-bold text-center pt-0 mb-3 mt-3 my-3">
        {i18n.t('products.title0')}
              </h2>
              <p className="text-justify section-description mt-1 pt-0 w-responsive mx-auto mb-5">
        {i18n.t('products.text0')}
              </p>


<Modal1
  toggle={this.toggle(1)}
  modal={this.state.modal1}
></Modal1>

<Modal2
  toggle={this.toggle(2)}
  modal={this.state.modal2}
></Modal2>

<Modal3
  toggle={this.toggle(3)}
  modal={this.state.modal3}
></Modal3>

<Modal4
  toggle={this.toggle(4)}
  modal={this.state.modal4}
></Modal4>

<Modal5
  toggle={this.toggle(5)}
  modal={this.state.modal5}
></Modal5>

<Modal6
  toggle={this.toggle(6)}
  modal={this.state.modal6}
></Modal6>

<Modal7
  toggle={this.toggle(7)}
  modal={this.state.modal7}
></Modal7>

<Modal8
  toggle={this.toggle(8)}
  modal={this.state.modal8}
></Modal8>

              <MDBRow>
{/* ------------------------------------------------------------------------------------ */}
{/* INTERRUPTOR */}
{/* ------------------------------------------------------------------------------------ */}

    <MDBCol md="6" className="mx-auto text-justify text-center">

      <MDBRow className="mx-auto">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(1)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={image1}
    alt="aligment"
    className="img-fluid"
  />

  <a>
    <MDBMask overlay="white-slight"/>
  </a>
</MDBView>

            <MDBCardBody className="text-justify mx-5 pb-0">
              <a style={{color: Colors.padrao}} onClick={this.toggle(1)}>
                <h5 className="text-center font-weight-bold mt-2 mb-3">
          <MaterialCommunityIconsIcon name="lightbulb-on-outline" size={23} style={{color: Colors.padrao}} />
          {i18n.t('products.type1')}
                </h5>
              </a>
              <p>
          {i18n.t('products.text1')}
              </p>
              <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(1)}>
              {i18n.t('home.button1')}
              </MDBBtn>
            </MDBCardBody>
                        
      </MDBRow>

{/* ------------------------------------------------------------------------------------ */}
{/* JARDIM */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(2)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image2}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(2)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <EntypoIcon name="water" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type2')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text2')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(2)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>
                        
    </MDBRow>


{/* ------------------------------------------------------------------------------------ */}
{/* HUB */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(3)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image3}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(3)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <FeatherIcon name="hard-drive" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type3')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text3')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(3)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>
                        
    </MDBRow>



{/* ------------------------------------------------------------------------------------ */}
{/* CHUVEIRO */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(4)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image4}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(4)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <FontAwesomeIcon name="shower" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type4')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text4')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(4)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>
                        
    </MDBRow>




    </MDBCol>


{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/*
        DIVISÃO ENTRE 1ª E 2ª COLUNAS
*/}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}

{/* ------------------------------------------------------------------------------------ */}
{/* CORTINA */}
{/* ------------------------------------------------------------------------------------ */}

<MDBCol md="6">

<MDBRow className="mx-auto">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(5)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image5}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(5)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <MaterialCommunityIconsIcon name="window-open-variant" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type5')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text5')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(5)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>

              </MDBRow>

{/* ------------------------------------------------------------------------------------ */}
{/* PISCINA */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(6)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image6}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(6)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <MaterialCommunityIconsIcon name="waves" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type6')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text6')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(6)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>

    </MDBRow>

{/* ------------------------------------------------------------------------------------ */}
{/* TERMÔMETRO */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(7)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image7}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(7)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <FontAwesomeIcon name="thermometer-3" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type7')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text7')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(7)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>

    </MDBRow>


{/* ------------------------------------------------------------------------------------ */}
{/* LED */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="mx-auto my-4">

<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves onClick={this.toggle(8)}> {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
<img
src={image8}
alt="aligment"
className="img-fluid"
/>

<a>
<MDBMask overlay="white-slight"/>
</a>
</MDBView>

      <MDBCardBody className="text-justify mx-5 pb-0">
        <a style={{color: Colors.padrao}} onClick={this.toggle(8)}>
          <h5 className="text-center font-weight-bold mt-2 mb-3">
    <MaterialCommunityIconsIcon name="led-on" size={23} style={{color: Colors.padrao}} />
    {i18n.t('products.type8')}
          </h5>
        </a>
        <p>
    {i18n.t('products.text8')}
        </p>
        <MDBBtn color="deep-purple" size="md" className="waves-light " onClick={this.toggle(8)}>
        {i18n.t('home.button1')}
        </MDBBtn>
      </MDBCardBody>
                        
    </MDBRow>


      </MDBCol>
    </MDBRow>
        
<hr className="my-5" />

{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* FIM DO PORTFOLIO COM 8 PRODUTOS (HUB + INTERRUPTOR + CORTINA + IRRIGAÇÃO + PISCINA + TERMÔMETRO + AQUECEDORES DE ÁGUA + LED COM DIMMER) */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------ */}

<MDBRow className="text-justify mx-auto pb-0">
                  
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/*  <YoutubeEmbed embedId="9tYXUhzeDR0" />  https://www.youtube.com/watch?v=9tYXUhzeDR0 */}
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link1')} /> {/* https://www.youtube.com/watch?v=Z-j8AxKvtww */}
                  </MDBCol>
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <FontAwesome5BrandsIcon name="apple" size={23} style={{color: Colors.padrao}} />
          <FontAwesomeIcon name="android" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type9')}
                      </h5>
                      <p>
          {i18n.t('products.text90')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text91')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text92')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text93')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text94')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text95')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text96')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <FoundationIcon name="mobile-signal" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type10')}
                      </h5>
                      <p>
          {i18n.t('products.text100')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text101')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text102')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text103')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text104')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text105')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link2')} /> {/* https://www.youtube.com/watch?v=1FKlYuHntME */}
                  </MDBCol>
                
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link3')} /> {/* https://www.youtube.com/watch?v=KIYdV6np3bg */}
                  </MDBCol>
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="clock-fast" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type11')}
                      </h5>
                      <p>
          {i18n.t('products.text110')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text111')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text112')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text113')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text114')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text115')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

                    <img src={logoIcon} style={{width: '25px'}}/>
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type12')}
                      </h5>
                      <p>
          {i18n.t('products.text120')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text121')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text122')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text123')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text124')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text125')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link4')} /> {/* https://www.youtube.com/watch?v=Z-j8AxKvtww */}
                  </MDBCol>
                
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link5')} /> {/* https://www.youtube.com/watch?v=1FKlYuHntME */}
                  </MDBCol>
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="home-city-outline" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type13')}
                      </h5>
                      <p>
          {i18n.t('products.text130')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text131')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text132')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text133')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text134')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text135')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text136')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="light-switch" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type14')}
                      </h5>
                      <p>
          {i18n.t('products.text140')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text141')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text142')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text143')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text144')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text145')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text146')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link6')} /> {/* https://www.youtube.com/watch?v=KIYdV6np3bg */}
                  </MDBCol>
                
</MDBRow>
              
                  
                                <hr className="my-5" />
                  
                  
                  
<MDBRow className="text-justify mx-auto pb-0">
                  
                  <MDBCol lg="5" className="text-lg-center">
    {/* *** PRECISA ADICIONAR "?&rel=0" AO FINAL DO LINK PRA NÃO FICAR EXIBINDO VÍDEOS RELACIONADOS *** */}
      <YoutubeEmbed embedId={i18n.t('products.link7')} /> {/* https://www.youtube.com/watch?v=Z-j8AxKvtww */}
                  </MDBCol>
                
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="remote-tv" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="11" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('products.type15')}
                      </h5>
                      <p>
          {i18n.t('products.text150')}
                      </p>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text151')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text152')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text153')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text154')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text155')}<br/></li>
                      <li className="p-2 align-content-stretch">{i18n.t('products.text156')}<br/></li>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
</MDBRow>


                                <hr className="my-5" />
                  
<MDBRow className="text-justify mx-auto pb-0">
                  
                <MDBCol lg="6">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <FontAwesome5BrandsIcon name="apple" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>
                    
                    <MDBCol xl="11" md="11" size="10">
                      
          <a href={i18n.t('products.link8')} style={{color: Colors.padrao}} target="_blank">{i18n.t('products.text160')}</a>
          
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol lg="6">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <FontAwesomeIcon name="android" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>
                    
                    <MDBCol xl="11" md="11" size="10">
          <a href={i18n.t('products.link9')} style={{color: Colors.padrao}} target="_blank">{i18n.t('products.text161')}</a>
          
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
</MDBRow>
                  

          </section>
          
        );
    }
}

export default Products;