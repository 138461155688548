import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal60 from '../images/products/pump/app.jpg';
import modal61 from '../images/products/pump/1P.jpg';
import modal62 from '../images/products/pump/pump1.jpg';
import modal63 from '../images/products/pump/pump2.jpg';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal60')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="10" >
                    
              <MDBRow>
        {i18n.t('products.modal61')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal62')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal63')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal64')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal60}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal61}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal65')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal62}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal66')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal63}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal67')}
  </h5>
</a>

                </MDBCol>

              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;