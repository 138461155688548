import React, { Component } from 'react';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { MDBRow } from 'mdbreact';

import { i18n } from "./../translate/i18n";

const color0 = "#E2F4F6";
const color1 = "#AEE2E7";
const color2 = "#8DB6BA";
const color3 = "#7396BA";
const color4 = "#4B8ACB";
const color5 = "#CBA14B";
const color6 = "#F8D91E";
const color7 = "#FAE360";
const color8 = "#94F79C";
const color9 = "#F8F0C4";
const color10 = "#E4DBAD";

const scrollToTop = () =>{
  window.scrollTo({
    top: 0, 
    behavior: 'auto' /* you can also use 'auto' behaviour in place of 'smooth' */
  });
  //alert("HISTORY");
};

class History extends Component
{
    render()
    {
      scrollToTop();

        return(

          <section className="my-0 px-2 pb-1">
          <h2 className="p-4 h1-responsive font-weight-bold text-center pt-0 mb-3 mt-3 my-3">
    {i18n.t('history.titleX')}
          </h2>

<MDBRow>

    <Timeline lineColor={'#ddd'} >
      <TimelineItem
        key="001"
        dateText={i18n.t('history.time0')}
        dateInnerStyle={{ background: color0, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color0 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title0')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description0')}</h4>
        <p>{i18n.t('history.text0')}</p>
      </TimelineItem>

      <TimelineItem
        key="002"
        dateText={i18n.t('history.time1')}
        dateInnerStyle={{ background: color1, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color1 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title1')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description1')}</h4>
        <p>{i18n.t('history.text1')}</p>
      </TimelineItem>

      <TimelineItem
        key="003"
        dateText={i18n.t('history.time2')}
        dateInnerStyle={{ background: color2, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color2 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title2')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description2')}</h4>
        <p>{i18n.t('history.text2')}</p>
      </TimelineItem>

      <TimelineItem
        key="004"
        dateText={i18n.t('history.time3')}
        dateInnerStyle={{ background: color3, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color3 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title3')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description3')}</h4>
        <p>{i18n.t('history.text3')}</p>
      </TimelineItem>

      <TimelineItem
        key="005"
        dateText={i18n.t('history.time4')}
        dateInnerStyle={{ background: color4, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color4 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title4')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description4')}</h4>
        <p>{i18n.t('history.text4')}</p>
      </TimelineItem>

      <TimelineItem
        key="006"
        dateText={i18n.t('history.time5')}
        dateInnerStyle={{ background: color5, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color5 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title5')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description5')}</h4>
        <p>{i18n.t('history.text5')}</p>
      </TimelineItem>

      <TimelineItem
        key="007"
        dateText={i18n.t('history.time6')}
        dateInnerStyle={{ background: color6, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color6 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title6')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description6')}</h4>
        <p>{i18n.t('history.text6')}</p>
      </TimelineItem>

      <TimelineItem
        key="008"
        dateText={i18n.t('history.time7')}
        dateInnerStyle={{ background: color7, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color7 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title7')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description7')}</h4>
        <p>{i18n.t('history.text7')}</p>
      </TimelineItem>

      <TimelineItem
        key="009"
        dateText={i18n.t('history.time8')}
        dateInnerStyle={{ background: color8, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color8 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title8')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description8')}</h4>
        <p>{i18n.t('history.text8')}</p>
      </TimelineItem>

      <TimelineItem
        key="010"
        dateText={i18n.t('history.time9')}
        dateInnerStyle={{ background: color9, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color9 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title9')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description9')}</h4>
        <p>{i18n.t('history.text9')}</p>
      </TimelineItem>

      <TimelineItem
        key="011"
        dateText={i18n.t('history.time10')}
        dateInnerStyle={{ background: color10, color: '#000' }}
        bodyContainerStyle={{
          background: '#ddd',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        }}
        className="mx-auto text-justify"
        style={{ color: color10 }}
      >
        <h2 className="mx-auto text-center">{i18n.t('history.title10')}</h2>
        <h4 style={{fontWeight: 'bold'}}>{i18n.t('history.description10')}</h4>
        <p>{i18n.t('history.text10')}</p>
      </TimelineItem>

    </Timeline>

          <h2 className="p-1 h1-responsive font-weight-bold pt-0 mb-1 mt-3 my-3 mx-auto text-justify text-center">
    {i18n.t('history.titleXI')}
          </h2>

</MDBRow>
          </section>
        );
    }
}

export default History;