import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal10 from '../images/products/motor/app.jpg';
import modal11 from '../images/products/motor/control.jpg';
import modal12 from '../images/products/motor/engine.jpg';
import modal13 from '../images/products/motor/qr code.jpg';
import modal14 from '../images/products/motor/shaft fit.jpg';

class Modal5 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal50')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
    <MDBRow className="text-justify pb-2">
                <MDBCol md="10" >
                    
                <MDBRow>
        {i18n.t('products.modal51')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal52')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal53')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal54')}
              </MDBRow>
              <br />

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal10}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-75 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal11}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal55')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-75 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal12}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal56')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-75 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal13}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal57')}
  </h5>
</a>

                </MDBCol>

                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-75 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal14}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal58')}
  </h5>
</a>

                </MDBCol>
              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal5;