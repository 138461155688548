/*
PARA FICAR DA FORMA IDEAL, SERIA IMPORTANTE JOGAR TODO O TEXTO AQUICOM SUAS QUEBRAS DE LINHA <br/>, 
PARÁGRAFOS <p></p>, ETC. MAS NÃO HÁ TEMPO HÁBIL E FICOU ASSIM. OS LINKS ABAIXO PODEM SOLUCIONAR ISSO NO FUTURO:
https://github.com/i18next/react-i18next/issues/189 -> VER COMENTÁRIO (mwaeckerlin commented on May 18, 2020)
https://github.com/remarkablemark/html-react-parser
*/

const messages = {
    pt: {
        translations: {
            header: {
                idiom: "Idioma"
            },
            drawer: {
                main: "Início",
                products: "Casa Inteligente",
                projects: "Projetos e Prototipação",
                history: "Histórico da empresa",
                contact: "Contato",
                user: "Logar ou Cadastrar"
            },
            home: {
                title0: "O quê nós fazemos?",
                text0: "",
                
                type1: "Produtos",
                title1: "Casa Inteligente",
                text1: "Desenvolvemos um portfolio próprio de equipamentos de automação residencial, integrados ao nosso app. Todas as nossas aplicações são baseadas em tecnologia sem fio (wireless) e em Internet das Coisas (IoT).",
                text11: "Nossos produtos existentes conectam a casa ao seu smartphone: iluminação, controle de persianas / cortinas, climatização de ambiente, irrigação de jardim, bombas de piscina, etc.",
                text12: "Instalações comerciais e edifícios mais antigos também podem ser controlados/monitorados através do mesmo sistema Dragonfly sem adaptação. Temos a melhor e mais fácil configuração de automação do mercado.",
                button1: "Saiba mais",

                type2: "Customização",
                title2: "Projetos e Prototipação",
                text2: "Realizamos todas as etapas de desenvolvimento de novos produtos eletrônicos e de automação desde a definição do escopo, desenvolvimento do projeto, criação dos arquivos, elaboração (layout) e finalização (assembly) das placas eletrônicas de circuito impresso (PCB) até a entrega do produto final (com acabamento) para nossos clientes.",
                text21: "projetos customizados para clientes;",
                text22: "possibilidade de integrar um produto externo conectado ao nosso sistema de IoT ou criar um projeto exclusivo e independente.",
                button2: "Saiba mais",

                type3: "Base sólida",
                title3: "Dominamos as tecnologias",
                text3: "Temos prototipado e validado muitos projetos diferentes até agora, antes de vender nossos produtos. Nosso primeiro projeto foi realizado em 2015 e desenvolvemos muitos outros. É por isso que tem nos trazido uma forte experiência em muitos campos da eletrônica e nos deixa realmente confiantes para ir mais longe. Devido ao nosso histórico, seguiremos realizando os 2 núcleos principais de nossa empresa:",
                text31: "desenvolver novos produtos próprios e integrá-los ao nosso sistema;",
                text32: "desenvolver novos projetos externos e customizados para clientes. ",
                button3: "Saiba mais",

                prod1: "Controle de residências",
                prod2: "Aplicativo próprio",
                prod3: "Interruptores touch",
                prod4: "Cortinas motorizadas",
                prod5: "Irrigação de jardins",
                prod6: "Chuveiros de baixo consumo",
                prod7: "Temômetros digitais",

                proj1: "Projetos de circuitos",
                proj2: "Desenvolvimento de layouts",
                proj3: "Renderização 3D",
                proj4: "Renderização 3D",
                proj5: "Finzalização de produtos",

                hist1: "Experiência em projetos",
                hist2: "Evolução de versões",
                hist3: "Drivers para mecanismos",
                hist4: "Projetos diversificados",
                hist5: "Reconhecido por clientes"
            },
            products: {
                title0: "Nossos Produtos",
                text0: "Agregue valor ao seu imóvel com baixo investimento em automação, sem necessidade de adaptação das instalações (quebrar paredes ou gerar sujeira) e sem necessidade de mais cabeamento (e custos extras) devido à tecnologia sem fio (wireless). Novas construções têm até 32% de redução do custo de cabeamento.",

                type1: "Interruptores Touch para Iluminação",
                text1: "Os interruptores Dragonfly Touch substituem os atuais interruptores de iluminação domésticos/empresariais. Este dispositivo Dragonfly executa funções liga/desliga com um único toque e também pode ser controlado pelo seu celular ou tablet.",
                
                type2: "Controladores de Irrigação/Tubulação",
                text2: "Esses controladores Dragonfly são conectados à mangueira de irrigação de sua residência. Este dispositivo Dragonfly executa funções abertura/fechamento de água com um único toque e também pode ser controlado pelo seu celular ou tablet.",
                
                type3: "Hub - Central de Automação do Local",
                text3: "O Hub Dragonfly permite você controlar qualquer outro dispositivo Dragonfly pelo app do seu celular ou tablet. Mas todos os outros dispositivos Dragonfly conseguem funcionar sem o Hub, mas com controle/monitoramento apenas manual.",
                
                type4: "Aquecedores com Baixo Consumo de Energia",
                text4: "Os aquecedores de água Dragonfly podem ser acoplados a chuveiros do mercado. Este dispositivo Dragonfly executa a função de ajuste de temperatura através do controle remoto e também pode ser controlado pelo seu celular ou tablet.",
                
                type5: "Motores para Cortinas e Persianas",
                text5: "A motorização Dragonfly pode ser aplicada em cortinas e persianas do mercado. Este dispositivo Dragonfly executa a função de abertura/fechamento através do controle remoto e também pode ser controlado pelo seu celular ou tablet.",
                
                type6: "Controladores de Bombas de Água",
                text6: "Esses controladores Dragonfly são conectados à bomba da piscina de sua residência. Este dispositivo Dragonfly executa funções liga/desliga da bomba de água com um único toque e também pode ser controlado pelo seu celular ou tablet.",
                
                type7: "Termômetros para Monitorar Ambientes",
                text7: "Os termômetros Dragonfly são conectados diretamente na tomada do ambiente que você deseja monitorar. Este dispositivo Dragonfly executa a função de leitura da temperatura ambiente e também pode ser monitorado pelo seu celular ou tablet.",
                
                type8: "Iluminação LED com Variação de Brilho",
                text8: "As lâmpadas LED Dragonfly são usadas para iluminar um ambiente local. Este dispositivo Dragonfly executa a função de ajuste de brilho através do controle remoto e também pode ser controlado pelo seu celular ou tablet.",
                
                type9: "Aplicativo (App) de Automação e Controle",
                text90: "Esse vídeo mostra várias funcionalidades do app Dragonfly como cadastrar uma nova conta, logar no aplicativo, cadastrar um novo local de automação e criar cômodo para organizar a residência, que vão além das funções de automação e controle. Veja a seguir os vários benefícios:",
                text91: "Aplicativo (app) próprio com toda a integração necessária com todos os dispositivos Dragonfly;",
                text92: "Aplicativo (app) disponível na loja Apple e na Google Play (links do app estão no fim dessa página);",
                text93: "Desenvolvimento focado na experiência do usuário com comandos e configurações muito simples;",
                text94: "Compartilhamento de usuários (membros) para controlar/monitorar a mesma residência/negócio;",
                text95: "Após adquirir qualquer dispositivo Dragonfly, o uso do app Dragonfly é grátis no 1º ano. A partir do 2º ano, o custo é de apenas USD 0.99 / mês;",
                text96: "Toda nova funcionalidade desenvolvida para o app é automaticamente atualizada no seu smartphone.",
                
                type10: "Reinventando o Plug & Play e o Modo de Configuração",
                text100: "Esse vídeo mostra o motivo de o Sistema Dragonfly ser o melhor do mercado. Cadastrar um Hub (central de automação de um local) ou qualquer dispositivo Dragonfly é extremamente fácil e rápido. Não há comparação de outros sistemas com o nosso Sistema Dragonfly:",
                text101: "Ligue o dispositivo Dragonfly na tomada (Hub ou qualquer outro dispositivo)",
                text102: "Insira no app o Serial do dispositivo Dragonfly que deseja configurar e controlar/monitorar;",
                text103: "Aguarde a sincronização do dispositivo com o Sistema Dragonfly;",
                text104: "O dispositivo aparecerá automaticamente na tela do app;",
                text105: "Pronto: em torno de 2 minutos para Hubs e em menos de 1 minuto para todos os demais dispositivos, você consegue controlar/monitorar qualquer dispositivo Dragonfly.",
                
                type11: "Reinventando o Tempo Real",
                text110: "Esse vídeo mostra o aplicativo (app) Dragonfly sendo utilizado como controle e monitoramento de uma lâmpada. Os comandos para ligar/desligar a lâmpada são atualizados imediatamente na tela do app. Não há comparação de outros sistemas com o nosso Sistema Dragonfly:",
                text111: "Menor latência do mercado com verdadeiro Tempo Real;",
                text112: "É possível controlar/monitorar todos os dispositivos Dragonfly pelo nosso próprio app;",
                text113: "Comandos liga/desliga da lâmpada executados manualmente pelo próprio usuário também atualizam o status do dispositivo na tela do app Dragonfly em tempo real;",
                text114: "Todos os comandos executados através do app e também manualmente ficam registrados e você consegue visualizar o histórico dessas ações na aba \"Atividade\" da tela principal para acompanhamento;",
                text115: "Todas essas funcionalidades (escritas acima e no vídeo) também ocorrem em todos os dispositivos Dragonfly: motores para cortina/persiana, irrigação de jardim, bombas de água, termômetros, etc..",
                
                type12: "Um Sistema, Tudo Conectado",
                text120: "Esse vídeo mostra a vantagem competitiva do Sistema Dragonfly em relação a qualquer outro. Todos os dispositivos interligados por um único sistema e controle/monitoramento tanto manualmente como pelo app:",
                text121: "Um único aplicativo para controlar e monitorar tudo;",
                text122: "Portfolio de dispositivos Dragonfly maior que os concorrentes e adicionando novos frequentemente;",
                text123: "O melhor custo/benefício do mercado;",
                text124: "Diferente de concorrentes que só têm controle/automação para ligar/desligar lâmpadas (previsão de tempo, alarme ou envio de e-mail não constituem controle/automação), nós realmente fazemos sua casa se tornar inteligente;",
                text125: "Até o final de 2022, teremos também eletrodomésticos integrados ao nosso Sistema Dragonfly.",
                
                type13: "Reinventando as Construções",
                text130: "Esse vídeo mostra porque instalar interruptores Dragonfly simplifica a fiação de uma construção. Os produtos Dragonfly não precisam de adaptação em construções mais antigas, com maior ganho na economia de cabeamento para novas construções (até 32%) e controle também pelo app Dragonfly. São muitos benefícios:",
                text131: "Comunicação wireless para controlar e/ou monitorar;",
                text132: "Não precisa de adaptação das instalações e sem necessidade de cabeamento extra (e custos extras);",
                text133: "Os interruptores Dragonfly podem ser controlados pelo app Dragonfly, onde você estiver no mundo;",
                text134: "Sem necessidade de quebrar paredes ou gerar sujeira;",
                text135: "O seu imóvel aumenta seu valor de venda;",
                text136: "Novas construções têm até 32% de redução do custo de cabeamento.",
                
                type14: "Reinventando o Modo de Configuração",
                text140: "Esse vídeo mostra porque configurar interruptores Dragonfly para controlar a mesma lâmpada/circuito é a melhor forma, mais simples e mais rápida do mercado. É o verdadeiro plug and play e não há necessidade de ação durante a instalação, ou seja, basta ligar na energia e usar. Veja a seguir os vários benefícios:",
                text141: "Basta ligar os interruptores Dragonfly na energia para usá-los;",
                text142: "Configuração wireless (automática) para controlar a mesma lâmpada/circuito;",
                text143: "Sem necessidade de cabeamento (e custos extras) para controlar a mesma lâmpada;",
                text144: "Facilmente configurável (o verdadeiro plug and play);",
                text145: "Seriais (S/N) únicos para cada produto e modelos que se conectam entre si;",
                text146: "Até 20 diferentes interruptores podem controlar a mesma lâmpada.",
                
                type15: "Reinventando a Automação e Controle",
                text150: "Esse vídeo mostra porque os motores Dragonfly para cortina/persiana são os melhores. É o verdadeiro plug and play entre o controle remoto da parede e o motor da cortina/persiana e não há necessidade de configuração entre eles durante a instalação, ou seja, basta ligar na energia e usar. São muitos benefícios:",
                text151: "Basta ligar os motores Dragonfly (para cortinas e persianas) e seus controles remotos na energia para usá-los, sem necessidade de cabeamento (e custos extras) entre si",
                text152: "Configuração wireless (automática) entre motor e controle remoto da parede;",
                text153: "Controles remotos embutidos em paredes deixam o ambiente livre de controles remotos tradicionais;",
                text154: "O motor também pode ser controlado pelo app Dragonfly, onde quer que você esteja no mundo;",
                text155: "Facilmente configurável (o verdadeiro plug and play);",
                text156: "Até 20 diferentes controles remotos podem controlar o mesmo motor.",
                


                modal10: "Interruptores Touch",
                modal11: "Basta tocar na superfície indicada ou enviar um comando através do seu celular/tablet para ligar/desligar suas lâmpadas.",
                modal12: "Nenhuma adaptação de circuito elétrico, apenas substitua seus interruptores existentes pelos interruptores Dragonfly. Além disso, os interruptores Dragonfly Touch se encaixam nos principais espelhos de interruptores de luz do mercado.",
                modal13: "Você também pode interconectar até 20 interruptores escravos para controlar a mesma lâmpada/circuito. Nenhum fio é necessário para conectá-los entre si devido à tecnologia sem fio. Explicamos essa interconexão para controlar a mesma lâmpada/circuito nessa mesma página mais abaixo.",
                modal14: "Além disso, funciona com todos os tipos de lâmpadas, ou seja, lâmpadas LED, lâmpadas dicróicas, lâmpadas de tungstênio, lâmpadas fluorescentes, etc.",

                modal15: "1 lâmpada",
                modal16: "2 lâmpadas",
                modal17: "3 lâmpadas",
                modal18: "6 lâmpadas",

                modal20: "Controladores de Irrigação",
                modal21: "Os controladores de irrigação/tubulação Dragonfly são dispositivos que permitem o usuário controlar qualquer irrigação (pressão de trabalho: 0.04Mpa-0.80Mpa) de forma manual ou pelo aplicativo Dragonfly.",
                modal22: "Apesar de ter sido projetado originalmente para irrigação, esses dispositivos também podem ser utilizados para controlar qualquer fluxo de água em instalações residenciais e comercias como em toilets, registros de água ou reservatórios, desde que as especificações (pressão de trabalho e tensão) sejam compatíveis.",
                modal23: "Basta tocar na superfície indicada no controle da válvula de água ou enviar um comando através do seu celular/tablet (pelo aplicativo Dragonfly) para abrir/fechar o fluxo de água. Nenhuma adaptação de circuito elétrico é necessário, apenas conecte a válvula de água ao controle e este à fonte de energia.",
                modal24: "Os controles das válvulas de água são iguais aos interruptores de lâmpadas e se encaixam nos principais espelhos de interruptores de luz do mercado. Você também pode interconectar até 20 controles para controlar a mesma irrigação. Nenhum fio é necessário para conectá-los entre si devido à tecnologia sem fio. Explicamos essa interconexão para controlar a mesma irrigação nessa mesma página mais abaixo.",
                modal25: "As válvulas possuem conexões padrão 1/2\", voltagem 110V ou 220V e taxa de vazão de 0.02Mpa a 5L/min.",
                
                modal26: "Controle da válvula",
                modal27: "Conexões 1/2\"",
                modal28: "Instalação pronta",
                modal29: "Válvula instalada",

                modal30: "Hub - Central de Automação do Local",
                modal31: "O Hub Dragonfly é o aparelho que permite a todos os outros dispositivos (interruptor de lâmpadas, cortina, bomba da piscina, etc.) a se comunicarem com o app Dragonfly.",
                modal32: "Todos os dispositivos Dragonfly funcionam de forma autônoma, mas somente através do Hub que é possível controlar/monitorar esses dispositivos Dragonfly pelo app.",
                modal33: "Com o Hub Dragonfly, você consegue controlar/monitorar toda a automação local onde quer que você esteja ao redor do mundo! Basta que seu smartphone tenha conexão com a internet para controlar/monitorar sua residência e/ou negócio.",
                modal34: "A configuração de um Hub Dragonfly é a mais fácil, rápida e simples do mercado: basta ligar seu Hub à tomada e solicitar ao app Dragonfly para reconhecê-lo, tudo sem fio. Após isso, basta adicionar os dispositivos de automação (interruptor de lâmpadas, cortina, bomba da piscina, etc.) uma única vez pelo app e você está pronto para controlar/monitorar sua residência e/ou negócio! Explicamos as configurações de Hubs e outros dispositivos Dragonfly em um vídeo nessa mesma página mais abaixo.",
                
                modal35: "Controle",
                modal36: "Histórico",
                modal37: "Organização",
                modal38: "Membros",

                modal40: "Aquecedores com Baixo Consumo de Energia",
                modal41: "Os aquecedores Dragonfly podem ser acoplados à tubulação existente (conexões padrão 1/2\") para aquecer água/líquidos de várias formas: chuveiros, banheiras de hidromassagem, piscinas, etc.",
                modal42: "Há vários benefícios: baixíssimo consumo de energia (potência de apenas 46W contra uma média de 5500W dos chuveiros tradicionais), tamanho pequeno (comprimento um pouco maior que um smartphone), verdadeiro plug and play (apenas acople-o à tubulação de água e ligue-o na tomada), controle através de uma tela de toque (touch screen) com vedação a prova de água e também pode ser controlado pelo seu celular ou tablet.",
                modal43: "Com tão baixo consumo de energia (equivalente a 3 lâmpadas LED), você não precisa de aquecimento solar (de alto custo) já que o aquecimento de H2O é o maior custo de energia residencial.",
                
                modal44: "Tela de controle",
                modal45: "Engenharia avançada",
                modal46: "Aquecimento chuveiro",
                modal47: "Aquecimento piscina",

                modal50: "Cortinas e Persianas Motorizadas",
                modal51: "Você consegue controlar sua cortina ou persiana através do nosso controle remoto touch (instalado como um interruptor na parede) ou enviar comandos através do seu celular/tablet para ajustar o percentual de sua abertura (veja a imagem do aplicativo à direita).",
                modal52: "Nenhuma adaptação de circuito elétrico, apenas conecte os motores Dragonfly na tomada e no trilho apropriado das cortinas ou persianas (existentes no mercado).",
                modal53: "Basta ligar o motor e o controle remoto na energia para que eles sincronizem entre si (não há necessidade de configuração entre eles) e você já pode controlar o motor pelo controle remoto imediatamente.",
                modal54: "Você também pode controlar os motores Dragonfly através de até 20 diferentes controles remotos. Nenhum fio é necessário para conectá-los entre si devido à tecnologia sem fio. Explicamos nossa motorização/automação de cortinas e persianas nessa mesma página mais abaixo.",

                modal55: "Controle remoto",
                modal56: "Simples e pequeno",
                modal57: "Setup simples",
                modal58: "Motor encaixado",

                modal60: "Controladores de Bombas",
                modal61: "Os controladores de bombas Dragonfly são dispositivos que permitem o usuário controlar qualquer bomba de líquidos (até 30A - 277VAC) de forma manual ou pelo aplicativo Dragonfly.",
                modal62: "Basta tocar na superfície indicada no controle da bomba de água ou enviar um comando através do seu celular/tablet (pelo aplicativo Dragonfly) para ligar/desligar a bomba de água/líquido. Nenhuma adaptação de circuito elétrico, apenas conecte a sua bomba de água ao controle e este à fonte de energia.",
                modal63: "Os controles das bombas de água/líquido são iguais aos interruptores de lâmpadas e se encaixam nos principais espelhos de interruptores de luz do mercado. Você também pode interconectar até 20 controles para controlar a mesma bomba. Nenhum fio é necessário para conectá-los entre si devido à tecnologia sem fio. Explicamos essa interconexão para controlar a mesma bomba nessa mesma página mais abaixo.",
                modal64: "Os controladores Dragonfly podem controlar bombas com correntes de até 30A e voltagem até 277VAC.",
                
                modal65: "Controle da bomba",
                modal66: "Sistema existente",
                modal67: "Conectado à bomba",

                modal70: "Medidores de Temperatura, Umidade e Pressão para Ambientes",
                modal71: "Na verdade, os termômetros Dragonfly passaram a ser dispositivos digitais que permitem o usuário monitorar qualquer ambiente em termos de temperatura (-40°C a 85°C), umidade (0%RH a 100%RH) e pressão atmosférica (10 a 2000 mbar) através de sua tela local ou pelo aplicativo Dragonfly.",
                modal72: "Basta ligar esse dispositivo Dragonfly na tomada e começar a monitorar a temperatura ambiente imediatamente (lançamento em 03/2022). Isso é ideal para acompanhar melhor as condições de um ambiente como um quarto de um bebê ou qualquer local que precisa manter condições específicas.",
                modal73: "Em breve, vamos liberar novas funcionalidade no app como configurar um alarme para quando a leitura dos sensores atigirem determinados valores estabelecidos pelo usuário e também a integração com equipamentos de climatização de ambientes como ar condicionados, aquecedores e humidificadores.",
                modal74: "Os sensores Dragonfly têm alta-resolução (24 bits / 12 bits para umidade): até 0.016 mbar, 0.04%RH, 0.01°C.",
                
                modal75: "Ligue na tomada USB",
                modal76: "Temperatura",
                modal77: "Umidade",
                modal78: "Pressão",

                modal80: "Lâmpadas LED com Variação de Brilho",
                modal81: "As lâmpadas LED Dragonfly são equivalentes às lâmpadas tradicionais dimerizáveis, combinando o baixo consumo de energia através do LED com o ajuste de brilho ideal das lâmpadas dimerizáveis. Basta tocar na superfície indicada no controle de brilho (dimmer) Dragonfly ou enviar um comando através do seu celular/tablet (pelo aplicativo Dragonfly) para ajustar o percentual de brilho do ambiente.",
                modal82: "Nenhuma adaptação de circuito elétrico, apenas substitua suas lâmpadas existentes pelas lâmpadas LED Dragonfly e seus dimmers pelo controle de brilho (dimmer) Dragonfly.",
                modal83: "Você também pode interconectar até 20 controles de brilho (dimmers) Dragonfly para controlar a mesma lâmpada LED. Nenhum fio é necessário para conectá-los entre si devido à tecnologia sem fio. Explicamos essa interconexão para controlar a mesma lâmpada nessa mesma página mais abaixo.",
                modal84: "Estamos em homologação de nossas lâmpadas LED Dragonfly, com vendas iniciando em 09/2023.",
                
                modal85: "Controle de brilho",
                modal86: "Lâmpada LED",
                modal87: "Foco no ambiente",

                
                link1: "RMhpsWypiPI?&rel=0&loop=1",
                link2: "Bfk8jCp9U9Q?&rel=0&loop=1",
                link3: "-j_4BznyB4A?&rel=0&loop=1",
                link4: "pJw6e7zJBoI?&rel=0&loop=1",
                link5: "eYZ2b4rlz_0?&rel=0&loop=1",
                link6: "9X7UPm0pVjo?&rel=0&loop=1",
                link7: "-Yak_YHBvwc?&rel=0&loop=1",
                link8: "https://apps.apple.com/us/app/dragonfly-iot/id1546804872",
                link9: "https://play.google.com/store/apps/details?id=com.dragonfly_app",

                text160: "Clique aqui para download do app Dragonfly em iOS (smartphone/tablet)",
                text161: "Clique aqui para download do app Dragonfly em Android (smartphone/tablet)",

                modalBt: "Fechar"
            },
            projects: {
                title0: "Projetos, Prototipação e Engenharia de Produto",
                text0: "Se você deseja tranformar um equipamento ou máquina para ter capacidade de ser automatizado, controlado ou monitorado através de um celular, tablet ou computador, compartilhe conosco sua necessidade e agregue alto valor ao seu negócio, transformando o seu projeto ou equipamento em um objeto comunicável pela internet. Dominamos tudo sobre o mundo de IoT (Internet of Things ou Internet das Coisas) e conseguimos fazer qualquer equipamento ser capaz de trocar dados pela internet com segurança.",
                
                type1: "Integração Simples",
                text1: "Você já tem um equipamento ou máquina e deseja ter ou já tem sua própria API (REST ou Arquitetura em Tempo Real - com WebSocket), mas precisa que a gente integre ambos entre si.",
                
                type2: "Integração Avançada",
                text2: "Você já tem um equipamento ou máquina e deseja ter ou já tem seu próprio aplicativo (Android e/ou iOS), mas precisa que a gente faça toda a integração entre ambos.",
                
                type3: "Integração Completa",
                text3: "Você já tem um equipamento ou máquina, mas deseja que a gente desenvolva tudo, inclusive o aplicativo (Android e/ou iOS). Isso transformará o seu equipamento em um objeto comunicável pela internet.",

                type4: "Enorme Experiência em Projetos",
                text4: "Trabalhamos com as melhores tecnologias do mercado para que a sua experiência seja tão boa quanto a sua ideia. Somos pioneiros em muitas aplicações empregadas em nossos produtos. Temos experiência em aplicações industriais, residenciais e comerciais. Clique nas imagens abaixo para ver alguns de nossos projetos ampliados:",

                type5: "Somos Especializados em Prototipação",
                text5: "A prototipação consiste em testar a viabilidade de um projeto através da fabricação de poucas unidades de um hardware. Com algumas unidades, é possível testar se o circuito do hardware é o mais apropriado e se há necessidade de ajustar algum de seus componentes. Além disso, também permite a avalição do melhor acabamento do produto e de sua integração com o aplicativo e/ou API antes de produzi-lo em larga escala de consumo, evitando grandes recalls e problemas com clientes.",
                type6: "Engenharia de Produtos",
                text6: "Além do desenvolvimento do hardware, temos experiência em engenharia de produtos, com desenvolvimento de acabamentos externos em vários materiais diferentes (Resina, ABS, PLA, PETG, Nylon, PC, plástico transparente, ASA, TPU, Alumínio, Aço Inox, Titanium, Aço Ferramental, etc.) para dar forma final ao seu produto. Começamos com uma ideia de forma, desenhamos em softwares CAD e entregamos o produto final para você poder vender o seu produto em pequena/larga escala.",

                type7: "Reconhecido por Clientes",
                text7: "Atingimos os melhores resultados e somos reconhecidos por nossos clientes pelos projetos que desenvolvemos porque aplicamos as melhores práticas do mercado mundial:",
                text71: "Profissionais com larga experiência técnica e de gestão;",
                text72: "Desenvolvimento em parceria com engenheiros dos Estados Unidos e da China;",
                text73: "Desenvolvimento padronizado, mas atendendo a customização dos clientes;",
                text74: "Gerenciamento de projetos utilizando metodologias ágeis;",
                text75: "Utilização de tecnologias e protocolos que são reconhecidos e legalizados internacionalmente;",
                text76: "Nível de segurança equivalente ao de sistemas de bancos.",

                client1: "Cliente dos EUA",
                client2: "Cliente da Europa",
                client3: "Cliente da Europa"
            },
            history: {
                titleX: "Nossa História",

                time0: "05/2015",
                title0: "INÍCIO",
                description0: "CRIAÇÃO DA EMPRESA",
                text0: "A empresa iniciou sua primeira pesquisa de mercado com a intenção de ser uma empresa de venda de drones. Naquele momento, o mercado de drones estava iniciando uma crescente demanda. Após finalizada a pesquisa, ficou concluído que já havia uma grande quantidade de players nesse mercado e que haveria a necessidade de enormes quantidades de unidades compradas para poder concorrer no preço praticado pelo mercado. \n\nEntretanto, nome e logotipos da empresa foram criados visando o mercado de drones, o que é mantido até o momento. \n\nA partir da pesquisa, ficou decidido que o mercado alvo passaria a ser o de desenvolvimento de equipamentos eletrônicos residenciais e comerciais.",
                
                time1: "05/2015 – 01/2016",
                title1: "DESENVOLVIMENTO DE HARDWARE",
                description1: "1ª FASE DOS PROJETOS ELETRÔNICOS",
                text1: "Após as dificuldades iniciais, foram atingidas as primeiras metas para o desenvolvimento de hardware: as primeiras Placas de Circuito Impresso (PCI - PCB em inglês) ficaram prontas e foram levadas para a produção externa. Em 02/2016, as primeiras PCBs ficaram prontas e a meta de entendimento do processo completo de prototipação foi atingida.",
                
                time2: "02/2016 – 03/2017",
                title2: "DOMÍNIO DE PROJETOS",
                description2: "AUTOMAÇÃO RESIDENCIAL POR RÁDIO FREQUÊNCIA",
                text2: "Como os projetos iniciais ( diagramas eletro-eletrônicos e layouts de PCBs ) eram desenvolvidos por um engenheiro terceirizado, ficou definido que todo o desenvolvimento de projetos seria incorporado pela empresa e passou-se a não mais terceirizar os projetos. O ganho em qualidade e velocidade dos projetos ficou cada vez mais evidente e essa prática continua até hoje. Como desejado desde o início da empresa, todos os projetos de comunicação foram desenvolvidos com tecnologia sem fio (wireless). Nessa etapa, os projetos conseguiram atingir a meta de automação residencial completa através de rádio frequência, que era o principal breakout de comunicação sem fio disponível no mercado naquele momento.",
                
                time3: "04/2017 – 12/2018",
                title3: "INTERNET DAS COISAS",
                description3: "AUTOMAÇÃO RESIDENCIAL VIA INTERNET",
                text3: "Mesmo já tendo testado comandos externos via internet com os equipamentos iniciais, essa etapa da empresa ficou marcada pelo aprofundamento para que todos os hardwares desenvolvidos pudessem ser controlados, automatizados ou monitorados à distância, via internet. Um enorme esforço foi recompensado com a meta sendo atingida em dezembro de 2018.",
                
                time4: "01/2019 – 02/2020",
                title4: "ESTABILIZAÇÃO DOS PRODUTOS",
                description4: "MELHORIA DO HARDWARE E MUDANÇA PARA WIFI",
                text4: "Com o domínio de IoT atingido, o foco passou para a fase de testes do sistema e foi identificado que a comunicação por rádio frequência era instável. Nessa fase, foram desenvolvidos equipamentos para automação residencial com tecnologia WiFi e a mudança para a arquitetura atual do sistema de comunicação, com total atenção para a segurança da informação dos usuários. A meta de finalização dos equipamentos de automação residencial foi atingida e a primeira residência começou a testar o sistema.",
                
                time5: "03/2020 - 11/2020",
                title5: "CONSOLIDAÇÃO DOS PROJETOS",
                description5: "EQUIPAMENTOS CONSOLIDADOS E PROJETOS INTERNACIONAIS",
                text5: "Nessa etapa, foram finalizadas as principais APIs tanto em arquitetura REST como em arquitetura baseada em Tempo Real. Nós também estabilizamos as primeiras versões do nosso app e validamos o correto funcionamento de todos os nossos equipamentos (chegamos à 8ª versão de nosso hardware, que foi a primeira geração totalmente estável e consolidada de equipamentos). Em paralelo, conseguimos desenvolver nossos primeiros projetos externos para Europa e Estados Unidos sob demanda, o que consolidou nosso domínio sobre as tecnologias de automação e controle.",

                time6: "12/2020 - 07/2021",
                title6: "APP NA LOJA GOOGLE PLAY",
                description6: "SISTEMA COMPLETAMENTE INTEGRADO E APP EM MODO ABERTO",
                text6: "Considerado um dos momentos mais desafiadores da empresa. Decidimos não aceitar novos projetos externos para finalizarmos o que faltava para lançar nossos produtos no mercado. Realizamos a completa integração entre equipamentos, app e serviço na nuvem. Conseguimos incluir funcionalidades essenciais como atualização na tela do app para comandos realizados manualmente por usuários e também a correção de status de sinal e conexão dos equipamentos (tudo em tempo real). Lançamos a primeira versão do nosso app em modo aberto na loja Google Play em 07/2021.",
                
                time7: "08/2021  – 12/2021",
                title7: "CONSOLIDAÇÃO DOS PRODUTOS",
                description7: "AJUSTES, ESCRITÓRIO PRÓPRIO E INÍCIO DE ENGENHARIA DE PRODUTOS",
                text7: "Algumas funcionalidades foram incluídas no app como a versão em inglês, inclusão de membros para controlar a mesma residência, inclusão e atualização de hubs em tempo real, registro/visualização de ações executadas pelos usuários, possibilidade de retornar o hub para modo fábrica, correções em alguns status de equipamentos, etc. A versão em modo aberto na loja Apple Store foi lançada em 11/2021. Nossa nova página web foi lançada em 12/2021 em Português e Inglês. Além disso, os interruptores de 3/6 lâmpadas e os controladores de irrigação foram homologados e passaram a integrar o portfolio de produtos para vendas. Nesse período, criamos nossos primeiros acabamentos externos (enclosures) para vender nossos produtos e iniciamos o escritório no CDN em Uberlândia.",

                time8: "01/2022 – 12/2022",
                title8: "INÍCIO DAS VENDAS",
                description8: "APP ESTABILIZADO E PRODUTOS PRONTOS PARA VENDA",
                text8: "Nossas vendas iniciaram em 11/2022. Em termos de melhorias no app/sistema, priorizamos corrigir falhas e estabilizar a versão que está nas lojas para melhorar a experiência dos usuários. Também houve inclusão da funcionalidade de cadastro de hubs e devices por QR Code (sem a necessidade de digitação dos seriais), alteração da barra de seleção de ambientes e migração da funcionalidade de membros de um mesmo local para dentro do drawer inserido na última versão. Além disso, incluímos a confirmação de cadastro via link por email (auth0) e ampliamos a capacidade operacional de número de usuários usando o sistema ao mesmo tempo. Na parte de hardware, novos equipamentos foram lançados: motores com maior torque para cortinas/persianas/aplicações maiores, aquecedores de água com baixo consumo de energia, tela de controle touch, termômetros digitais com leitura de temperatura/umidade/pressão. Houve estabilização do sincronismo entre devices escravos (interruptores, controles, etc.) com os mestres (motores, interruptores, controladores, etc.). A parte de engenharia de produtos (enclosures) foi efetivada, melhorada e ampliada para termos produtos finais para os usuários.",

                time9: "01/2023 – 06/2023",
                title9: "B2B NO MERCADO NACIONAL",
                description9: "ESTRATÉGIA B2B NACIONAL E CRESCIMENTO NAS VENDAS",
                text9: "Com o início das vendas, nossa prioridade é dar o melhor suporte aos clientes e ampliar nossas vendas, alinhando com nossa capacidade produtiva. Novas funcionalidades serão incluídas no app como agendamento de ações automáticas de rotina periódica, abertura de chamados para suporte técnico e ativação de comandos/rotinas cadastrados quando o usuário estiver a uma determinada distância da residência. Planejamos estabelecer parcerias com revendedores no Brasil e ampliar as vendas dentro do território. Em termos de hardware, a novidade prevista é de LEDs com variação de brilho (dimer sem fio), novo hub com conexão à rede celular (sem necessidade de WiFi), além de atualização de todo o portfólio para novas versões.",

                time10: "07/2023 – 12/2023",
                title10: "EMPRESA INTERNACIONAL",
                description10: "INÍCIO DE UMA OPERAÇÃO NO EXTERIOR E VENDAS GLOBAIS",
                text10: "Temos a previsão de lançar um portal para revendores poderem configurar residências. Pretendemos lançar integração completa para eletrodomésticos, além de hubs para automóveis com extensão dos conceitos de automação sem fio para veículos. Também planejamos lançar um medidor de energia integrado a fornecedores de energia. Nosso foco será iniciarmos uma operação no exterior em 07/2023 e ampliarmos nossas vendas em escala global. Voltaremos a desenvolver projetos sob demanda a partir do momento que tivermos o escritório no exterior.",
                
                titleXI: "Novidades em breve..."
            },
            contact: {
                title0: "Entre em contato",
                text0: "Temos todos os canais a seguir para que você entre em contato e possa sanar todas as suas dúvidas.",
                
                title1: "Envie uma mensagem:",
                text1: "Preencha os dados abaixo e envie sua mensagem ao clicar no botão \"Enviar\".",
                
                userbox: "Nome",
                mailbox: "Email",
                sjtbox: "Assunto",
                msgbox: "Sua mensagem",
                
                title2: "Contato direto:",
                text2: "Se preferir, você pode entrar em contato conosco através dos canais abaixo.",
                
                email: "EMAIL",
                phone: "TELEFONE COMERCIAL",
                whatsapp: "WHATSAPP",
                address: "LOCALIZAÇÃO",
                time: "HORÁRIO",

                btnsend: "Enviar",
                
                textWA: "Clique aqui para entrar em contato",
                linkWA: "https://wa.me/message/XRGKT7ALLS24H1"
            },
            policy: {
                title0: "POLÍTICA DE PRIVACIDADE",
                text00: "Este aplicativo é mantido e operado por Dragonfly IoT.",
                text01: "Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).",
                text02: "Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:",
                text03: "Quem deve utilizar nosso aplicativo;",
                text04: "Quais dados coletamos e o que fazemos com eles;",
                text05: "Seus direitos em relação aos seus dados pessoais; e",
                text06: "Como entrar em contato conosco.",
                
                title1: "1. Quem deve utilizar nosso aplicativo",
                text10: "Nosso aplicativo somente deve ser utilizado por pessoas que tenham, pelo menos, 4(quatro) anos de idade, sendo que a utilização por pessoa com menos de 18(dezoito) anos somente será possível mediante o consentimento de pelo menos um de seus pais ou responsável.",

                title2: "2. Dados que coletamos e motivos da coleta",
                text20: "Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.",
                option21: "1. Dados pessoais fornecidos expressamente pelo usuário",
                text21: "Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso aplicativo:",
                text22: "Nome;",
                text23: "Endereço de e-mail;",
                text24: "Número de telefone;",
                text25: "Endereço físico.",
                text26: "A coleta destes dados ocorre nos seguintes momentos:",
                text27: "Cadastro no aplicativo.",
                text28: "Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:",
                text29: "Para identificação do usuário na plataforma;",
                text210: "Para poder prestar suporte aos usuário dos nossos dispositivos.",
                
                option22: "2. Dados sensíveis",
                text211: "Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.",
                
                option23: "3. Coleta de dados não previstos expressamente",
                text212: "Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.",
                text213: "Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do aplicativo.",
                
                title3: "2. Dados que coletamos e motivos da coleta",
                text214: "Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.",
                
                title4: "4. Por quanto tempo seus dados pessoais serão armazenados",
                text215: "Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis.",
                text216: "Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.",

                title5: "5. Bases legais para o tratamento de dados pessoais",
                text217: "Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.",
                text218: "Todas as nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.",
                
                title6: "6. Direitos do usuário",
                text219: "O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:",
                text220: "confirmação da existência de tratamento;",
                text221: "acesso aos dados;",
                text222: "correção de dados incompletos, inexatos ou desatualizados;",
                text223: "anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;",
                text224: "portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;",
                text225: "eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;",
                text226: "informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;",
                text227: "informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;",
                text228: "revogação do consentimento.",
                text229: "É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados seja desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.",
                
                option61: "1. Como o titular pode exercer seus direitos",
                text230: "Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.",
                
                title7: "7. Medidas de segurança no tratamento de dados pessoais",
                text231: "Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.",
                text232: "As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.",
                text233: "Entre as medidas de segurança adotadas por nós, destacamos as seguintes:",
                text234: "Armazenamento de senhas utilizando hashes criptográficos;",
                text235: "Restrições de acessos a banco de dados;",
                text236: "Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.",
                text237: "De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.",
                
                title8: "8. Reclamação a uma autoridade de controle",
                text238: "Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.",
                
                title9: "9. Alterações nesta política",
                text239: "A presente versão desta Política de Privacidade foi atualizada pela última vez em: 23/07/2021.",
                text240: "Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.",
                text241: "Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.",
                
                title10: "10. Como entrar em contato conosco",
                text242: "Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:",
                text243: "E-mail: contato@iotdragonfly.com",
                text244: "Telefone: +55 34 3304-5775",
                text245: "Endereço postal: Av. Jaime Ribeiro da Luz, 971 - Sala 52 - Bairro Santa Mônica - Uberlândia - MG"
            }
        }
    }
}
/*
Somos uma empresa de desenvolvimento de produtos eletrônicos, automação e controle. Veja abaixo qual opção melhor se adapta à sua necessidade (Prototipação ou Projetos):
*/
export {messages}