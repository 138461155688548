import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal80 from '../images/products/led/app.jpg';
import modal81 from '../images/products/led/control.jpg';
import modal82 from '../images/products/led/led.jpg';
import modal83 from '../images/products/led/room.jpg';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal80')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="10" >
                    
              <MDBRow>
        {i18n.t('products.modal81')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal82')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal83')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal84')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal80}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal81}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal85')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal82}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal86')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="4" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal83}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal87')}
  </h5>
</a>

                </MDBCol>

              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;