import AntDesign_ttf from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import Entypo_ttf from 'react-native-vector-icons/Fonts/Entypo.ttf';
import Feather_ttf from 'react-native-vector-icons/Fonts/Feather.ttf';
import FontAwesome_ttf from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import FontAwesome5_ttf from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
import FontAwesome5Brands_ttf from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf';
import Fontisto_ttf from 'react-native-vector-icons/Fonts/Fontisto.ttf';
import Foundation_ttf from 'react-native-vector-icons/Fonts/Foundation.ttf';
import Ionicons_ttf from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import MaterialCommunityIcons_ttf from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import MaterialIcons_ttf from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

const IconsCSS = `
@font-face {
  src: url(${AntDesign_ttf});
  font-family: AntDesign;
}
@font-face {
  src: url(${Entypo_ttf});
  font-family: Entypo;
}
@font-face {
  src: url(${Feather_ttf});
  font-family: Feather;
}
@font-face {
  src: url(${FontAwesome_ttf});
  font-family: FontAwesome;
}
@font-face {
  src: url(${FontAwesome5_ttf});
  font-family: FontAwesome5;
}
@font-face {
  src: url(${FontAwesome5Brands_ttf});
  font-family: FontAwesome5_Brands;
}
@font-face {
  src: url(${Fontisto_ttf});
  font-family: Fontisto;
}
@font-face {
  src: url(${Foundation_ttf});
  font-family: Foundation;
}
@font-face {
  src: url(${Ionicons_ttf});
  font-family: Ionicons;
}
@font-face {
  src: url(${MaterialCommunityIcons_ttf});
  font-family: MaterialCommunityIcons;
}
@font-face {
  src: url(${MaterialIcons_ttf});
  font-family: MaterialIcons;
}
`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
else style.appendChild(document.createTextNode(IconsCSS));

document.head.appendChild(style);