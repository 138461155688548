import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal20 from '../images/products/irrigation/app.jpg';
import modal21 from '../images/products/irrigation/1P.jpg';
import modal22 from '../images/products/irrigation/coupling.jpg';
import modal23 from '../images/products/irrigation/installation.png';
import modal24 from '../images/products/irrigation/irrigation.png';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal20')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="10" >
                    
              <MDBRow>
        {i18n.t('products.modal21')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal22')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal23')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal24')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal25')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal20}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal21}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal26')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal22}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal27')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal23}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal28')}
  </h5>
</a>

                </MDBCol>

                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal24}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal29')}
  </h5>
</a>

                </MDBCol>
              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;