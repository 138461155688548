import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal30 from '../images/products/hub/hub.jpg';
/*import modal31 from '../images/products/hub/smart.jpeg';*/
import modal31 from '../images/products/hub/smart.png';
import modal32 from '../images/products/hub/app1.jpg';
import modal33 from '../images/products/hub/app2.jpg';
import modal34 from '../images/products/hub/app3.jpg';
import modal35 from '../images/products/hub/app4.jpg';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal30')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="8" >
                    
              <MDBRow>
        {i18n.t('products.modal31')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal32')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal33')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal34')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="4" >

  <img
    src={modal30}
    alt="aligment"
    className="img-fluid"
  />

  <img
    src={modal31}
    alt="aligment"
    className="img-fluid"
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal32}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal35')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal33}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal36')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal34}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal37')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-50 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal35}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal38')}
  </h5>
</a>

                </MDBCol>

              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;