import { Routes, Route } from "react-router-dom";

import Contact from './contact';
import Home from './home';
import History from './history';
import Products from './products';
import Projects from './projects';
import Policy from "./policy";

const Main = () => (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/history" element={<History />} />
        <Route path="/history" element={<History />} />
        <Route path="/contact" element={<Contact />} />
        <Route exact path="/policy" element={<Policy/>} />
      </Routes>
)

export default Main;