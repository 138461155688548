import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal40 from '../images/products/heater/app.jpg';
import modal41 from '../images/products/heater/control.jpg';
import modal42 from '../images/products/heater/engineering.png';
import modal43 from '../images/products/heater/shower.png';
import modal44 from '../images/products/heater/pool.jpg';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal40')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="10" >
                    
              <MDBRow>
        {i18n.t('products.modal41')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal42')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal43')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal40}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal41}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal44')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal42}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal45')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal43}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal46')}
  </h5>
</a>

                </MDBCol>

                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal44}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal47')}
  </h5>
</a>

                </MDBCol>
              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;