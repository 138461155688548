const padrao = "#5e189f";//COR PADRÃO
//const padrao = "#33f321";//AMARELO
const branco = "#FFFFFF";
const Maincolor = "#6303A0";
const Gridcolor = "#A7A9AB";

const Colors =
{
  padrao,
  branco,
  Maincolor,
  Gridcolor,
}

export default Colors;