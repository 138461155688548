import React from "react";
import { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBMask, MDBView } from "mdbreact";
import Lightbox from "react-image-lightbox";
import "../utils/Lightbox.css";

import { i18n } from "../translate/i18n";

import Colors from '../utils/colors.js';

import EntypoIcon from 'react-native-vector-icons/dist/Entypo';
import MaterialCommunityIconsIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import IoniconsIcon from 'react-native-vector-icons/dist/Ionicons'; 

import presentation1 from './images/projects/IoT1.png';

import image1 from './images/projects/layout/components1.png';
import image2 from './images/projects/layout/components2.png';
import image3 from './images/projects/layout/components4.png';
import image4 from './images/projects/pcb/pcb 3.jpg';
import image5 from './images/projects/pcb/pcb 4.jpg';
import image6 from './images/projects/pcb/pcb 6.jpg';
import image7 from './images/projects/pcb/pcb 2.jpg';
import image8 from './images/projects/pcb/pcb 7.jpg';
import image9 from './images/projects/pcb/pcb 9.jpg';
import image10 from './images/projects/prototyping/4G 1.jpg';
import image11 from './images/projects/prototyping/evolution.jpg';
import image12 from './images/projects/prototyping/I 4.jpg';

import proto1 from './images/projects/prototyping/apresentacao 1.png';
import proto2 from './images/projects/prototyping/apresentacao 2.png';
import proto3 from './images/projects/prototyping/apresentacao 3.jpg';
import proto4 from './images/projects/prototyping/apresentacao 4.jpg';
import proto5 from './images/projects/prototyping/F 1.png';
import proto6 from './images/projects/prototyping/F 2.png';
import proto7 from './images/projects/prototyping/F 3.jpg';
import proto8 from './images/projects/prototyping/F 4.jpg';
import proto9 from './images/projects/prototyping/M 1.png';
import proto10 from './images/projects/prototyping/M 2.png';
import proto11 from './images/projects/prototyping/M 3.jpg';
import proto12 from './images/projects/prototyping/M 4.jpg';

import eng1 from './images/projects/engineering/irrigation CAD 1.png';
import eng2 from './images/projects/engineering/irrigation builder.png';
import eng3 from './images/projects/engineering/irrigation CAD.png';
import eng4 from './images/projects/engineering/irrigation REAL.jpg';

import eng5 from './images/projects/engineering/gear CAD 1.png';
import eng6 from './images/projects/engineering/gear builder.png';
import eng7 from './images/projects/engineering/gear CAD.png';
/*import eng8 from './images/projects/engineering/gear REAL 2.jpeg';*/
import eng8 from './images/projects/engineering/gear REAL 2.png';

import eng9 from './images/projects/engineering/hub CAD 1.png';
import eng10 from './images/projects/engineering/hub builder.png';
import eng11 from './images/projects/engineering/hub CAD.png';
import eng12 from './images/projects/engineering/hub REAL.jpg';

import cust1 from './images/projects/customer1.png';
import cust2 from './images/projects/customer2.png';
import cust3 from './images/projects/customer3.png';

class Projects extends Component
{
    state = {
        photoIndex1: 0,
        photoIndex2: 0,
        photoIndex3: 0,
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
        images1: [
            image1,
            image2,
            image3,
            image4,
            image5,
            image6,
            image7,
            image8,
            image9,
            image10,
            image11,
            image12
        ],
        images2: [
            proto1,
            proto2,
            proto3,
            proto4,
            proto5,
            proto6,
            proto7,
            proto8,
            proto9,
            proto10,
            proto11,
            proto12
        ],
        images3: [
            eng1,
            eng2,
            eng3,
            eng4,
            eng5,
            eng6,
            eng7,
            eng8,
            eng9,
            eng10,
            eng11,
            eng12
        ]
      }
      
      renderImages1 = () => {
        let photoIndex1 = -1;
        const { images1 } = this.state;
      
      return images1.map(imageSrc => {
        photoIndex1++;
        const privateKey = photoIndex1;
        return (
          <MDBCol md="4" key={photoIndex1}>
            <figure>
              <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={()=>
              this.setState({ photoIndex1: privateKey, isOpen1: true })
              }
              />
            </figure>
          </MDBCol>
          );
        })
      }
      
      renderImages2 = () => {
        let photoIndex2 = -1;
        const { images2 } = this.state;
      
      return images2.map(imageSrc => {
        photoIndex2++;
        const privateKey = photoIndex2;
        return (
          <MDBCol md="3" key={photoIndex2}>
            <figure>
              <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={()=>
              this.setState({ photoIndex2: privateKey, isOpen2: true })
              }
              />
            </figure>
          </MDBCol>
          );
        })
      }
      
      renderImages3 = () => {
        let photoIndex3 = -1;
        const { images3 } = this.state;
      
      return images3.map(imageSrc => {
        photoIndex3++;
        const privateKey = photoIndex3;
        return (
          <MDBCol md="3" key={photoIndex3}>
            <figure>
              <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={()=>
              this.setState({ photoIndex3: privateKey, isOpen3: true })
              }
              />
            </figure>
          </MDBCol>
          );
        })
      }
      
    render()
    {
        const { photoIndex1, photoIndex2, photoIndex3, isOpen1, isOpen2, isOpen3, images1, images2, images3 } = this.state;
        
        return(
            <section className="my-0 px-7 pb-5">
                
                <h2 className="p-4 h1-responsive font-weight-bold text-center pt-0 mb-3 mt-3 my-3">
          {i18n.t('projects.title0')}
                </h2>
                <p className="text-justify section-description mt-1 pt-0 w-responsive mx-auto mb-5">
          {i18n.t('projects.text0')}
                </p>

                <div className="mdb-lightbox p-3">

<MDBRow className="text-justify mx-auto pb-0">

{/* AAA */}
<MDBCol lg="4" className="text-lg-center">
  <img
    className="img-fluid"
    src={presentation1}
    alt=""
    style={{margin: 'auto', width: '335px', height: '315px'}}
  />
</MDBCol>
                
                <MDBCol lg="8">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="devices" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type1')}
                      </h5>
                      <p>
          {i18n.t('projects.text1')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="transit-connection-variant" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type2')}
                      </h5>
                      <p>
          {i18n.t('projects.text2')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <IoniconsIcon name="git-compare" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type3')}
                      </h5>
                      <p>
          {i18n.t('projects.text3')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                </MDBCol>
              </MDBRow>

{/* BBB */}
              <hr className="my-5" />

              <MDBRow className="text-justify mx-auto pb-0">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="electron-framework" size={35} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h2 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type4')}
                      </h2>
                      <p>
          {i18n.t('projects.text4')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                <MDBRow>
                    {this.renderImages1()}
                </MDBRow>
                
              <hr className="my-5" />

                </div>
                {isOpen1 && (
                <Lightbox
                mainSrc={images1[photoIndex1]}
                nextSrc={images1[(photoIndex1 + 1) % images1.length]}
                prevSrc={images1[(photoIndex1 + images1.length - 1) % images1.length]}
                imageTitle={photoIndex1 + 1 + "/" + images1.length}
                onCloseRequest={() => this.setState({ isOpen1: false })}
                onMovePrevRequest={() =>
                    this.setState({
                    photoIndex1: (photoIndex1 + images1.length - 1) % images1.length
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                    photoIndex1: (photoIndex1 + 1) % images1.length
                    })
                    }
                />
                )}

{/* CCC */}
              <div className="mdb-lightbox p-3">

              <MDBRow className="text-justify mx-auto pb-0">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <MaterialCommunityIconsIcon name="printer-3d" size={35} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h2 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type5')}
                      </h2>
                      <p>
          {i18n.t('projects.text5')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                <MDBRow>
                    {this.renderImages2()}
                </MDBRow>
                
                </div>
                {isOpen2 && (
                <Lightbox
                mainSrc={images2[photoIndex2]}
                nextSrc={images2[(photoIndex2 + 1) % images2.length]}
                prevSrc={images2[(photoIndex2 + images2.length - 1) % images2.length]}
                imageTitle={photoIndex2 + 1 + "/" + images2.length}
                onCloseRequest={() => this.setState({ isOpen2: false })}
                onMovePrevRequest={() =>
                    this.setState({
                    photoIndex2: (photoIndex2 + images2.length - 1) % images2.length
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                    photoIndex2: (photoIndex2 + 1) % images2.length
                    })
                    }
                />
                )}

              <hr className="my-5" />
              
{/* DDD */}
              <div className="mdb-lightbox p-3">

              <MDBRow className="text-justify mx-auto pb-0">
                    <MDBCol size="1" style={{color: Colors.padrao}} >
{/*          <MaterialCommunityIconsIcon name="package-variant" size={35} style={{color: Colors.padrao}} />*/}
          <MaterialCommunityIconsIcon name="ruler-square-compass" size={35} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h2 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type6')}
                      </h2>
                      <p>
          {i18n.t('projects.text6')}
                      </p>
                    </MDBCol>
                  </MDBRow>

                <MDBRow>
                    {this.renderImages3()}
                </MDBRow>
                
                </div>
                {isOpen3 && (
                <Lightbox
                mainSrc={images3[photoIndex3]}
                nextSrc={images3[(photoIndex3 + 1) % images3.length]}
                prevSrc={images3[(photoIndex3 + images3.length - 1) % images3.length]}
                imageTitle={photoIndex3 + 1 + "/" + images3.length}
                onCloseRequest={() => this.setState({ isOpen3: false })}
                onMovePrevRequest={() =>
                    this.setState({
                    photoIndex3: (photoIndex3 + images3.length - 1) % images3.length
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                    photoIndex3: (photoIndex3 + 1) % images3.length
                    })
                    }
                />
                )}

              <hr className="my-5" />

{/* EEE */}
            
            <MDBRow>

<MDBCol lg="4">
              
<MDBCarousel
background-color="yellow"
activeItem={1}
length={3}
showControls={true}
showIndicators={true}
className="rounded z-depth-2 mb-lg-0 mb-4 bg-dark text-black" hover waves
style={{width: '100%'}}
>
  <MDBCarouselInner>

  <MDBCarouselItem itemId="1">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={cust1}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '400px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('projects.client1')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

    <MDBCarouselItem itemId="2">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={cust2}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('projects.client2')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    
  <MDBCarouselItem itemId="3">
      <MDBView>
        <img
          className="rounded mx-auto d-block"
          src={cust3}
          alt="aligment"
          className="img-thumbnail"
          style={{margin: 'auto', width: '450px', height: '270px'}}
        />
      <MDBMask overlay="black-slight" />
      </MDBView>
      <MDBCarouselCaption>
        <h3>
          {i18n.t('projects.client3')}
        </h3>

      </MDBCarouselCaption>
    </MDBCarouselItem>
    

  </MDBCarouselInner>
</MDBCarousel>
</MDBCol>
                
{/* FFF */}
                <MDBCol lg="8">
                  <MDBRow className="mb-3">
                    <MDBCol size="1" style={{color: Colors.padrao}} >

          <EntypoIcon name="trophy" size={23} style={{color: Colors.padrao}} />
                    </MDBCol>

                    <MDBCol xl="10" md="11" size="10">
                      <h5 className="font-weight-bold mb-3" style={{color: Colors.padrao}} >
          {i18n.t('projects.type7')}
                      </h5>
                      <p>
          {i18n.t('projects.text7')}
                      </p>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text71')}<br/></li>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text72')}<br/></li>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text73')}<br/></li>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text74')}<br/></li>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text75')}<br/></li>
                      <li className="p-2 text-left align-content-stretch">{i18n.t('projects.text76')}<br/></li>
                    </MDBCol>
                  </MDBRow>

                </MDBCol>
              </MDBRow>
              
            </section>
        );
    }
}

export default Projects;