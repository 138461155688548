import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { i18n } from '../../translate/i18n';

import Colors from '../../utils/colors.js';

import modal70 from '../images/products/thermometer/app.jpg';
import modal71 from '../images/products/thermometer/thermometer1.jpg';
import modal72 from '../images/products/thermometer/thermometer2.jpg';
import modal73 from '../images/products/thermometer/thermometer3.jpg';
import modal74 from '../images/products/thermometer/thermometer4.jpg';

class Modal2 extends Component {
  render() {
    const { toggle, modal } = this.props;

    return (
      <MDBContainer>
        <MDBModal isOpen={modal} toggle={() => toggle()} centered size="lg">
          <MDBModalHeader toggle={() => toggle()} className="text-center text-white" titleClass="w-100 font-weight-bold" style={{background: Colors.padrao}} >
        {i18n.t('products.modal70')}
          </MDBModalHeader>

  <MDBModalBody>
    <MDBContainer fluid>
      
              <MDBRow className="text-justify  my-2">
                <MDBCol md="10" >
                    
              <MDBRow>
        {i18n.t('products.modal71')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal72')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal73')}
              </MDBRow>
              <MDBRow>
        {i18n.t('products.modal74')}
              </MDBRow>

                </MDBCol>
                
                <MDBCol md="2" >
  <img
    src={modal70}
    alt="aligment"
    className="img-fluid"
    style={{border: "solid", borderWidth: 1, borderColor: "black"}}
  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal71}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal75')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal72}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal76')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal73}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal77')}
  </h5>
</a>

                </MDBCol>
                
                <MDBCol md="3" >
                  
<MDBView size="sm" className="overlay rounded z-depth-2 w-100 mx-auto" waves > {/* w-75 = 75% da coluna // mx-auto = CENTRALIZADO */}
  <img
    src={modal74}
    alt="aligment"
    className="img-fluid"
  />
</MDBView>

<a style={{color: Colors.padrao}}>
  <h5 className="text-center font-weight-bold mt-2 mb-3">
{i18n.t('products.modal78')}
  </h5>
</a>

                </MDBCol>

              </MDBRow>

    </MDBContainer>
  </MDBModalBody>

  <MDBModalFooter>
    <MDBBtn color="deep-purple" size="md" onClick={e => toggle()}>{i18n.t('products.modalBt')}</MDBBtn>
  </MDBModalFooter>

</MDBModal>

      </MDBContainer>
    );
  }
}

export default Modal2;