import React from "react";
import { Component } from "react";
import { i18n } from "./../translate/i18n";

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBInput } from "mdbreact";

import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import MaterialCommunityIconsIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import IoniconsIcon from 'react-native-vector-icons/dist/Ionicons';

import Colors from '.././utils/colors.js';

class Contact extends Component
{
    render()
    {
        return(
            <section className="my-4">
                
      <h2 className="h1-responsive font-weight-bold text-center my-3">
        {i18n.t('contact.title0')}
      </h2>
      <p className="text-center w-responsive mx-auto pb-3">
        {i18n.t('contact.text0')}
      </p>

      <MDBRow>
        <MDBCol lg="5" className="lg-5 mb-4">
          <MDBCard>
            <MDBCardBody  className="padrao2">
              <div className="padrao1 form-header accent-1">
                <h3 className="mt-2">
                    {i18n.t('contact.title1')}
                </h3>
              </div>
              <p className="dark-grey-text pb-1">
                {i18n.t('contact.text1')}
              </p>

                        
                <div className="input-group mb-2 pb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                                <FeatherIcon name="user" size={17} style={{color: Colors.padrao}} />
                    </span>
                </div>
                <input type="text" className="form-control" placeholder={i18n.t('contact.userbox')} aria-label="Username" aria-describedby="basic-addon" />
                </div>

                
                <div className="input-group mb-2 pb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                                <FeatherIcon name="mail" size={17} style={{color: Colors.padrao}} />
                    </span>
                </div>
                <input type="text" className="form-control" placeholder={i18n.t('contact.mailbox')} aria-label="Your email" aria-describedby="basic-addon" />
                </div>


                <div className="input-group mb-2 pb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                                <MaterialCommunityIconsIcon name="tag-text-outline" size={17} style={{color: Colors.padrao}} />
                    </span>
                </div>
                <input type="text" className="form-control" placeholder={i18n.t('contact.sjtbox')} aria-label="Subject" aria-describedby="basic-addon" />
                </div>

            <MDBRow className="mb-0">
              <MDBCol md="1" size="1" className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                    <MaterialCommunityIconsIcon name="comment-text-multiple-outline" size={17} style={{color: Colors.padrao}} />
                    </span>
              </MDBCol>
              <MDBCol md="11" size="11">
                <p>
                    <MDBInput
                        className="white mb-0 pb-0"
                        label={i18n.t('contact.msgbox')}
                        type="textarea"
                        outline
                        size="lg"
                    />
                    
                </p>
              </MDBCol>
            </MDBRow>

              <div className="text-center">
                <MDBBtn color="deep-purple">{i18n.t('contact.btnsend')}</MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        
    <MDBCol lg="7" className="lg-0 mb-4">
        <MDBCard>
            <MDBCardBody  className="padrao2">
              <div className="padrao1 form-header accent-1">
                <h3 className="mt-2">
                    {i18n.t('contact.title2')}
                </h3>
              </div>
              <p className="dark-grey-text pb-1">
                    {i18n.t('contact.text2')}
              </p>
              
            <MDBRow className="mb-2 pb-1">
              <MDBCol md="1" size="2">
                    <FeatherIcon name="mail" size={35} style={{color: Colors.padrao}} />
              </MDBCol>
              <MDBCol md="11" size="10">
                <h5 className="font-weight-bold mb-1">{i18n.t('contact.email')}</h5>
                <p className="grey-text">
                    contato@iotdragonfly.com
                </p>
              </MDBCol>
            </MDBRow>
            
            <MDBRow className="mb-2 pb-1">
              <MDBCol md="1" size="2">
                    <FeatherIcon name="phone-call" size={35} style={{color: Colors.padrao}} />
              </MDBCol>
              <MDBCol md="11" size="10">
                <h5 className="font-weight-bold mb-1">{i18n.t('contact.phone')}</h5>
                <p className="grey-text">
                    +55 34 3304 6008
                </p>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mb-2 pb-1">
              <MDBCol md="1" size="2">

        <a style={{color: Colors.padrao}} onClick={()=> window.open(i18n.t('contact.linkWA'), "_blank")}>
                    <MaterialCommunityIconsIcon name="whatsapp" size={35} style={{color: Colors.padrao}} />
        </a>
              </MDBCol>
              <MDBCol md="11" size="10">
                <h5 className="font-weight-bold mb-1">{i18n.t('contact.whatsapp')}</h5>
                <p className="grey-text">
                    +55 34 3304 6008
                    
                    <a href={i18n.t('contact.linkWA')} style={{color: Colors.padrao}} target="_blank">{i18n.t('contact.textWA')}</a>
                </p>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mb-2">
              <MDBCol md="1" size="2">
                    <IoniconsIcon name="location-outline" size={35} style={{color: Colors.padrao}} />
              </MDBCol>
              <MDBCol md="11" size="10">
                <h5 className="font-weight-bold mb-1">{i18n.t('contact.address')}</h5>
                <p className="grey-text">
                    CDN Business Center - Av. Jaime Ribeiro da Luz, 971 - Sala 52 - Bairro Santa Mônica - Uberlândia - MG
                </p>
              </MDBCol>
            </MDBRow>
          
        </MDBCardBody>
        </MDBCard>
    </MDBCol>

      </MDBRow>

{/* https://google-map-generator.com/#select */}
        <MDBRow>
        <MDBCol lg="12" className="lg-0 mb-4">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: "400px"}}
          >
            <iframe
              src="https://maps.google.com/maps?q=Av.%20Jaime%20Ribeiro%20da%20Luz,%20971%20-%20Sala%2052&t=&z=13&ie=UTF8&iwloc=&output=embed"
              title="This is a unique title"
              width="100%"
              height="100%"
              frameBorder="5"
              style={{ border: 1 }}
            />
          </div>
        </MDBCol>
        </MDBRow>

            </section>
            
        );
    }
}

export default Contact;