/*
PARA FICAR DA FORMA IDEAL, SERIA IMPORTANTE JOGAR TODO O TEXTO AQUICOM SUAS QUEBRAS DE LINHA <br/>, 
PARÁGRAFOS <p></p>, ETC. MAS NÃO HÁ TEMPO HÁBIL E FICOU ASSIM. OS LINKS ABAIXO PODEM SOLUCIONAR ISSO NO FUTURO:
https://github.com/i18next/react-i18next/issues/189 -> VER COMENTÁRIO (mwaeckerlin commented on May 18, 2020)
https://github.com/remarkablemark/html-react-parser
*/

const messages = {
    en: {
        translations: {
            header: {
                idiom: "Idiom"
            },
            drawer: {
                main: "Home",
                products: "Smart Home",
                projects: "Projects & Prototyping",
                history: "Company History",
                contact: "Contact",
                user: "Log In or Sign Up"
            },
            home: {
                title0: "What do we do?",
                text0: "",
                
                type1: "Products",
                title1: "Smart Home",
                text1: "We develop our own product portfolio of home automation devices completely integrated to our app. All Dragonfly products are wireless technology and Internet of Things (IoT) based.",
                text11: "Our existing products connect home to your smartphone: lightning, blinds/curtains control, room climatizing, garden irrigation, pool pumps, etc;",
                text12: "Commercial facilities and older buildings can also be controlled/monitored through the same Dragonfly system without adaptation. We have the best and easiest setup of automation in the market.",
                button1: "Read more",

                type2: "Customizing",
                title2: "Projects & Prototyping",
                text2: "We develop all development steps of new products and automation such as scope definition, project development, files generation, PCB layout design generation, Printed Circuit Board (PCB) assembly and final product delivery (with enclosure and all needed).",
                text21: "customized projects for costumers;",
                text22: "possibility of integrating an external product connected to our existing IoT system or creating an exclusive and independent project.",
                button2: "Read more",

                type3: "Solid underlie",
                title3: "We master our technology",
                text3: "We have been prototyping and validating many different projects so far, before selling our products. Our first project had been performed in 2015 and we have developed many others. That's why it has been bringing us a strong experience in many electronic fields and it has made us truly confident to go further. Due to our history, we will keep on performing the 2 main cores of our company:",
                text31: "developing new own products and integrate them to our system;",
                text32: "developing new external and customized projects for customers.",
                button3: "Read more",
                                
                prod1: "Home control",
                prod2: "Own application",
                prod3: "Touch switches",
                prod4: "Motorized courtains",
                prod5: "Yard irrigation",
                prod6: "Low consumption showers",
                prod7: "Digital thermometers",

                proj1: "Circuit projects",
                proj2: "Layout development",
                proj3: "3D rendering",
                proj4: "3D rendering",
                proj5: "Products finishment",
                
                hist1: "Experienced in projects",
                hist2: "Version evolution",
                hist3: "Engine drivers",
                hist4: "Diversified projects",
                hist5: "Recognized by customers"
            },
            products: {
                title0: "Our products",
                text0: "Aggregate value to your real state with a low investment in automation, with no facility adaptation needed (broken walls or dirt generatted) and no need of extra wiring (and extra costs as well) due to our wireless technology. New constructions/buildings have up to 32% of wiring costs cutdown.",

                type1: "Touch Switches for Lighting",
                text1: "The Dragonfly Touch switches replace current home/company lighting switches. These Dragonfly devices perform on/off functions by a single touch and can also be controlled by your smartphone or tablet.",
                
                type2: "Irrigation/Piping Controllers",
                text2: "This Dragonfly controllers are plugged to a yard irrigation hose. This Dragonfly device can turn on/off water in both ways by touching the controller and also by using your smartphone (through the Dragonfly app).",
                
                type3: "Hub - Main Automation Interface",
                text3: "The Dragonfly Hub allows you to control any other Dragonfly device through iOS and Androind app (smartphone/tablet). However, all Dragonfly devices can work without a Hub, but only with manual controlling/monitoring.",
                
                type4: "Low Energy Consumption Heater",
                text4: "The Dragonfly water heater can be coupled directly to a common shower. This Dragonfly device can adjust water temperature in both ways by using its remote control and also by using your smartphone (through the Dragonfly app).",
                
                type5: "Motors for Curtains and Blinds",
                text5: "The Dragonfly motorizing can be coupled with market curtains/blinds. This Dragonfly device can perform curtain/blind opening adjustment in both ways by using remote control and also using your smartphone (through the Dragonfly app).",
                
                type6: "Water Pump Controllers",
                text6: "This Dragonfly controllers are connected to a residential water pump. This Dragonfly device can turn on/off a water pump in both ways by touching the controller and also by using your smartphone (through the Dragonfly app).",
                
                type7: "Thermometers for Room Monitoring",
                text7: "The Dragonfly thermometers are plugged directly to a power supply in any room for monitoring. This device can measure any room temperature/humidity and also be monitored by using your smartphone (through the Dragonfly app).",
                
                type8: "LED Lighting with Dimmer",
                text8: "The Dragonfly LED lamps are used for any room lighting. This Dragonfly device can perform lighting brightness adjustment in both ways by using its remote control (dimmer) and also by using your smartphone (through the Dragonfly app).",
                
                
                type9: "Automation and Controlling Application (App)",
                text90: "This video shows many features of the Dragonfly app like how to create a new account, log in to the app, register a new automation/controlling place and registering a new room to arrange your place, which are functions beyond automation and controlling. See the following benefits:",
                text91: "Own application (app) with all integration needed to sync all Dragonfly devices;",
                text92: "App available on Apple Store and Google Play (the app links on stores are placed at the end of this page);",
                text93: "Development focused on user experience with extremely simple commands and setups;",
                text94: "Place sharing (with membership) for controlling/monitoring the same place/facility;",
                text95: "After purchasing a Dragonfly Hub, usage of the Dragonfly app is free for the first year. From the second year, its usage is for only USD 0.99 / month;",
                text96: "Any new app developed feature is automatically updated on your smartphone (iOS/Android) right away.",
                
                type10: "Reinventing Plug & Play and the Setup Mode",
                text100: "This video shows why Dragonfly System is the best within the market. Setting up a Hub (local interface device between all devices and the app) or any Dragonfly device is extremely easy and fast. No comparison between other systems and the Dragonfly System:",
                text101: "Plug your Dragonfly device into power supply (Hub or any other device)",
                text102: "Type on the app screen your device Serial Number which you wish for setup and controlling/monitoring;",
                text103: "Wait up for syncing between device and the Dragonfly System;",
                text104: "Dragonfly device will automatically appear on the app screen;",
                text105: "It's done: in around 2 minutes for Hubs and less than 1 minute for all other devices, you can control/monitor any Dragonfly device.",
                
                type11: "Reinventing the Real Time",
                text110: "This video shows the Dragonfly Application (app) being used as controlling and monitoring of a lamp. Commands for turning on/off the lamp are right away updated on the app screen. No comparison between other systems and the Dragonfly System:",
                text111: "The lowest latency within the market with the true real time;",
                text112: "You can control/monitor all Dragonfly devices through our own app;",
                text113: "Turn on/off commands performed/triggered manually on the switch by any user (by touching it) also update the device status on the Dragonfly app screen in real time;",
                text114: "All commands performed/triggered through the app and also manually are registered and you can see the whole action history on the tab \"Activity\" inside the main screen for monitoring;",
                text115: "All those features (described above and on the side video) are also performed in any other Dragonfly device: motors for curtains/blinds, yard irrigation, water pumps, thermometers, etc..",
                
                type12: "One System, Everything Connected",
                text120: "This video shows the Dragonfly System competitive advantage compared to any other. All devices interconnected to an only system and controlling/monitoring as manually as through the app:",
                text121: "An only app for controlling and monitoring everything;",
                text122: "The Dragonfly portfolio is bigger than any competitor and adding new products frequently;",
                text123: "The best cost/benefit within the market;",
                text124: "Different from competitors that only have automation/controlling for turning on/off lamps (weather forecast, alarm or sending an e-mail are not home controlling/automation), we really make your home become smart;",
                text125: "By the end of 2022, we will have home appliances integrated to the Dragonfly System.",
                
                type13: "Reinventing Constructions",
                text130: "This video shows how a Dragonfly switch installation simplifies a facility wiring. All Dragonfly devices do not need any facility adaptation (even in older facilities), with higher potential savings in new constructions with up to 32% of wiring costs cutdown and controlling through the Dragonfly app as well. There are many benefits:",
                text131: "Wireless communication for controlling and/or monitoring;",
                text132: "No facility adaptation needed and no need of extra wiring (and extra costs as well);",
                text133: "Dragonfly switches can be controlled through the Dragonfly app, wherever you are all over the world;",
                text134: "No dirt generation and no broken walls;",
                text135: "Value aggregated to your real state with a low investment in automation;",
                text136: "New constructions/buildings have up to 32% of wiring costs cutdown.",
                
                type14: "Reinventing the Setup Mode",
                text140: "This video shows why setting up Dragonfly switches to control the same lamp/circuit is the best way, the simplest one and the fastest within the market. That's the only real plug & play and no action is needed during their installation, that is, just plug them into power supply and start using them. See the following benefits:",
                text141: "Just plug Dragonfly switches into power supply to start using them;",
                text142: "Wireless setup (automatic) to control the same lamp/circuit;",
                text143: "No need of wiring (and extra costs as well) to control the same lamp;",
                text144: "Extremely easy setup (the real plug & play);",
                text145: "Unique Serial Numbers (S/N) for each single product and models to connect each other;",
                text146: "Up to 20 different switches can control the same lamp.",
                
                type15: "Reinventing Automation and Controlling",
                text150: "This video shows why Dragonfly motorizing for curtain/blind are the best within the market. That's the real plug & play between remote controls on the wall and motors coupled in curtain/blind rails and no setup is needed between each other, that is, just plug them into power supply and start using them. So many benefits:",
                text151: "Just plug Dragonfly motors (for curtains and blinds) and their remote controls into power supply to use them, no wiring needed (and extra costs as well) between each other;",
                text152: "Wireless setup (automatic) between motor and remote control on the wall;",
                text153: "Remote controls adapted on the wall get your room rid of traditional remote controls;",
                text154: "Our motors can also be controlled by the Dragonfly app, wherever you are all over the world;",
                text155: "Easy setup (the real plug & play);",
                text156: "Up to 20 different remote controls can control the same motor.",
                


                modal10: "Touch Switches",
                modal11: "Just touch on the indicated surface or send a command through your smartphone/tablet to turn on/off your lamps.",
                modal12: "No electrical circuit adaptation is needed, just replace your current switches by Dragonfly switches. Also, Dragonfly Touch switches fit to main switches/lighting plates within the market.",
                modal13: "You can also interconnect up to 20 slave switches to control the same lamp/circuit. No wiring is needed for syncing them to each other due to our wireless technology. We explain this interconnection for controlling the same lamp/circuit below in this same page.",
                modal14: "Besides, it works with all kinds of lamps, that is, LED lamps, dichroic lamps, tungsten lamps, fluorescent lamps, etc.",

                modal15: "1 lamp",
                modal16: "2 lamps",
                modal17: "3 lamps",
                modal18: "6 lamps",

                modal20: "Irrigation/Piping Controllers",
                modal21: "The Dragonfly irrigation/piping controllers are devices that let users control any irrigation/piping (work pressure: 0.04Mpa-0.80Mpa) manually or through the Dragonfly app.",
                modal22: "Despite of being originally projected for irrigation, these devices can also be used to control any water flow in residential and commercial/business facilities as in toilets, shut off valves or reservoirs, since its specification (like work pressure, inlet connection and voltage) are followed.",
                modal23: "Just touch on the indicated surface in the water valve control or send a command through your smartphone/tablet (through the Dragonfly app) to turn on/off water flow. No electrical circuit adaptation is needed, just plug your water valve to its control and this to the power supply.",
                modal24: "The water valve controls are the same as lighting switches and they fit to the main lighting switch plates within the market. You can also interconnect up to 20 different controls to control the same irrigation/piping. No wiring is needed for syncing them to each other due to our wireless technology. We explain this interconnection for controlling the same irrigation/piping below in this same page.",
                modal25: "Our valves have standardized inlets of 1/2\", voltage 110V or 220V and water flow rate of 0.02Mpa a 5L/min.",
                
                modal26: "Water control",
                modal27: "Connections 1/2\"",
                modal28: "Installation ready",
                modal29: "Installed valve",

                modal30: "Hub - Main Automation Local Interface",
                modal31: "The Dragonfly Hub is the device that let all other devices (lighting switches, motorized curtains, pool pumps, etc.) communicate to the Dragonfly app.",
                modal32: "All Dragonfly devices work independently, but only through the Hub that it is possible to control/monitor these Dragonfly devices through the app.",
                modal33: "With the Dragonfly Hub, you  can control/monitor all local automation wherever you are all over the world! Just make sure your smartphone is connected to the internet for controlling/monitoring of home/business devices.",
                modal34: "The Dragonfly Hub setup is the easiest, fastest and simplest within the market: just plug your Hub to a power supply and tap on the Dragonfly app to sync it, everything wirelessly. Right after that, just add automation devices (lighting switches, motorized curtains, pool pump, etc.) for an only time through the app and you are done to control/monitor your home/business! We explain the Hub and other Dragonfly devices setup in a video below in this same page.",
                
                modal35: "Controlling",
                modal36: "History",
                modal37: "Arrangement",
                modal38: "Membership",

                modal40: "Low Energy Consumption Heater",
                modal41: "The Dragonfly heaters can be coupled to your existing piping (inlet connection standard 1/2\") to heat water/liquids in many ways: showers, hydromassage bathtubs, pools, etc.",
                modal42: "There are many benefits: very low energy consumption (power of 46W compared to an average of 5500W in tradicionals electric showers), low size (length a little bigger than a smartphone), true plug and play (just couple them to your existing water piping and plug them to a power supply), controlled by touch screen with water proof sealing and can also controlled through your smartphone or tablet.",
                modal43: "With such low power consumption (similar to 3 LED lamps), you do not need a solar heating system (a high investimento) since H2O heating is the highest cost of residential energy.",
                
                modal44: "Control screen",
                modal45: "Advanced engineering",
                modal46: "Shower heater",
                modal47: "Pool heater",

                modal50: "Motors for Curtains and Blinds",
                modal51: "You can control your curtain or blind through our touch remote control (installed like a lighting switch on a wall) or send commands through to change/move your blind/curtain opening percentage (see on the right side the app screen image).",
                modal52: "No electric circuit adaptation is needed, just plug the Dragonfly motors into a power supply and an appropriate curtain rail or roller blind (existing in the market).",
                modal53: "Just plug your motor and its remote control to a power supply so that they sync to each other (no setup is needed between them) and you can already control you motor by its remote control right away.",
                modal54: "You can even control Dragonfly motors by up to 20 different remote controls. No wiring is needed for syncing them to each other due to our wireless technology. We explain this motorizing/automation of curtains and blinds below in this same page.",

                modal55: "Remote control",
                modal56: "Small and simple",
                modal57: "Simple setup",
                modal58: "Motor coupled",

                modal60: "Water Pump Controllers",
                modal61: "The Dragonfly pump controllers are devices that let users control any liquid pump (up to 30A - 277VAC) in both ways manually or through the Dragonfly app.",
                modal62: "Just touch on the indicated surface in the water pump control or send a command through your smartphone/tablet (through the Dragonfly app) to turn on/off your water/liquid pump. No electrical circuit adaptation is needed, just plug your water pump to its control and this to the power supply.",
                modal63: "The water/liquid pump controls are the same as lighting switches and they fit to the main lighting switch plates within the market. You can also interconnect up to 20 different controls to control the same pump. No wiring is needed for syncing them to each other due to our wireless technology. We explain this interconnection for controlling the same pump below in this same page.",
                modal64: "The Dragonfly controllers can control pumps with current up to 30A and voltage up to 277VAC.",
                
                modal65: "Pump controller",
                modal66: "Existing system",
                modal67: "Plugged to a pump",

                modal70: "Temperature, Humidity and Pressure Gauges for Rooms",
                modal71: "Actually, the Dragonfly thermometers became digital devices that let users monitor any room in terms of temperature (-40°C to 85°C), humidity (0%RH to 100%RH) and atmospheric pressure (10 to 2000 mbar) in both way through its local screen/display or through the Dragonfly app.",
                modal72: "Just plug this Dragonfly device to a power supply and start monitoring your local room right away (sales starting from 03/2022). It is perfect for better monitoring any room like a baby room or any place that needs to keep specific conditions.",
                modal73: "Coming soon, we are going to release new app features like setting up an alarm to be triggered whenever sensors measurement achieve determined values set by user and also a integration to room climatizing equipments like air conditioners, heaters and humidifiers.",
                modal74: "The Dragonfly sensors are high-resolution (24 bits / 12 bits for humidity): up to 0.016 mbar, 0.04%RH, 0.01°C.",
                
                modal75: "Plug to USB power supply",
                modal76: "Temperature",
                modal77: "Humidity",
                modal78: "Pressure",

                modal80: "LED Lighting with Dimmer",
                modal81: "The Dragonfly LED lamps are equivalent to traditional lamps with dimmer, by combining low energy consumption of LED with ideal brightness adjustment of dimming lighting. Just touch on the indicated surface of Dragonfly LED control (dimmer) or send a command through your smartphone/tablet (through the Dragonfly app) to adjust the room brightness percentage.",
                modal82: "No electrical circuit adaptation is needed, just replace your current lamps by Dragonfly LED lamps and its Dragonfly LED control (dimmer). ",
                modal83: "You can also interconnect up to 20 different LED controls to control the same LED lamp. No wiring is needed for syncing them to each other due to our wireless technology. We explain this interconnection for controlling the same irrigation/piping below in this same page.",
                modal84: "We are in homologation of our Dragonfly LED lamps, and their sales are planned to start from 09/2023.",
                
                modal85: "LED control",
                modal86: "LED lamp",
                modal87: "Focused on rooms",

                
                link1: "Bbo_FVxisF8?&rel=0&loop=1",
                link2: "o800aGmW_Jg?&rel=0&loop=1",
                link3: "qn5I85uJxrA?&rel=0&loop=1",
                link4: "ryI3SCDLy5w?&rel=0&loop=1",
                link5: "DyWXfg4Ck4k?&rel=0&loop=1",
                link6: "l0eMOrNUlFM?&rel=0&loop=1",
                link7: "-UcUIopTq9U?&rel=0&loop=1",
                link8: "https://apps.apple.com/us/app/dragonfly-iot/id1546804872",
                link9: "https://play.google.com/store/apps/details?id=com.dragonfly_app",

                text160: "Tap/click here for app downloading in iOS (only mobile)",
                text161: "Tap/click here for app downloading in Android (only mobile)",


                modalBt: "Close"
            },
            projects: {
                title0: "Projects, Prototyping and Product Engineering",
                text0: "If you want to become your device or machine to be able to be completely automatized, controlled or monitored by a smartphone, tablet or computer, share with us your needs and aggregate high value to your business by becoming your project or device in a communication object through the internet. We master all about the world of IoT (which stands for Internet of Things) and we can make any device exchange data through the internet totally safe.",
                
                type1: "Simple Integration",
                text1: "You already have a device or machine and want develop or already have developed your own API (REST or Real Time Architecture - with WebSocket), but you need us to integrate them.",
                
                type2: "Advanced Integration",
                text2: "You already have a device or machine and want develop or already have developed your own app (Android and/or iOS), but you need us to integrate them.",
                
                type3: "Complete Integration",
                text3: "You already have a device or machine but want us to develop all the rest by including your app (Android and/or iOS). It will become your device in a communication object through the internet.",

                type4: "Huge Experience with Projects",
                text4: "We work with the best market technologies so that your experience be as good as your idea. We are pioneer in many applications used in our own products. We are experienced in different applications: industrial, residential and commercial. Click on images bellow so that you can see some of our projects magnified:",

                type5: "We are Specialized in Prototyping",
                text5: "Prototyping consists in testing a project feasibility through a few hardware samples manufacturing. With those few samples, it is possible to test if its hardware electronic circuit is the most proper and if there is any need to adjust any of its parts. In addition, it also allows us a real evaluation of what is the best product enclosure/finishment and of its integration to the application and/or API before manufacturing in mass scale for consumption by avoiding big recalls and troubles with customers.",

                type6: "Product Engineering",
                text6: "In addition to hardware development, we also have large experience in product engineering, with enclosures development for final products in many different materials (Resin, ABS, PLA, PETG, Nylon, PC, transparent plastic, ASA, TPU, Aluminum, Stainless Steel, Titanium, Tool Steel, etc.) for giving your product its final shape. We can start with an idea of shape, design it in CAD softwares and deliver the final product. All of this so you can start selling your products in low/large/mass scale.",

                type7: "Recognized by Customers",
                text7: "We achieve the best results and are recognized by our customers due to the projects that we develop because we apply the best practices of worldwide market:",
                text71: "Experienced professionals with both high technical and management specialization;",
                text72: "Development in partnership with engineers in USA and China;",
                text73: "Standardized development, but always following customer customization;",
                text74: "Project management by using agile methodologies;",
                text75: "Usage of technologies and protocols which are internationally recognized and legalized;",
                text76: "Security level as high as bank systems.",

                client1: "USA Customer",
                client2: "European Customer",
                client3: "European Customers"
            },
            history: {
                titleX: "Our History",

                time0: "05/2015",
                title0: "BEGINNING",
                description0: "COMPANY FOUNDATION",
                text0: "Our company began its first market research tending to be a drone reselling store. At that time, the drone market was starting a increasing demand. After finishing that research, we concluded that there was a lot of different players by competiting in this maket and we would need to purchase a high quantity of drones to competite in price with other competitors. However, the company name and its logo had already been created to match the drone market, which is still the same so far. Due to that research, we ended up deciding that our new target market would be changed to electronic devices development by focusing on residential and commercial.",
                
                time1: "05/2015 – 01/2016",
                title1: "HARDWARE DEVELOPMENT",
                description1: "1st STAGE OF ELECTRONIC PROJECTS",
                text1: "After our initial difficulties, we achieved our first hardware development goals: our first PCBs (which stands for Printed Circuit Boards) projects were finished and we outsourced that production. In 02/2016, the first PCBs were done and the target of understanding the whole prototyping process had been achieved.",
                
                time2: "02/2016 – 03/2017",
                title2: "PROJECTS MASTERING",
                description2: "HOME AUTOMATION THROUGH RADIO FREQUENCY",
                text2: "As our first projects (electric-electronic diagrams and PCB layouts) had been developed by an outsourcing engineer, we defined that all project development would be incorporated for our company and we ended up not outsourcing projects anymore. Quality and speed improvements were perceived more and more and we've been keeping this practice so far. As desired at the beginning of our company, all communication projects have been developing with wireless technology. At that step, projects were able to achieve the whole home automation through radio frequency, which was the main breakout circuit of wireless communication available for purchasing at that moment.",

                time3: "04/2017 – 12/2018",
                title3: "INTERNET OF THINGS",
                description3: "HOME AUTOMATION THROUGH INTERNET",
                text3: "In spite of already having tested external commands through internet with our first devices, this particular company step had been pointed out for deeper hardware development so that all devices could be controlled, automatized or monitored by a remote user, through the internet. This huge effort had been rewarded with an achieved goal in december of 2018.",
                
                time4: "01/2019 – 02/2020",
                title4: "PRODUCTS STABILIZATION",
                description4: "HARDWARE IMPROVEMENT AND ALL DEVICES BECAME WIFI BASED",
                text4: "With the IoT mastering achieved, we became focused on the next system tests and we identified that the radio frequency communication was unsteady. At that step, we redeveloped all home automation devices to be based on WiFi technology and changed to the current communication system architeture, which is fully based on user information safety. Our goal of home automation devices finishing had been achieved and the first home started testing our system.",
                
                time5: "03/2020 - 11/2020",
                title5: "PROJECTS CONSOLIDATION",
                description5: "DEVICES CONSOLIDATED AND INTERNATIONAL PROJECTS",
                text5: "That's when we finished our main APIs as in REST architeture as in Real Time based architeture. We also make steady our first app releases and validated all devices working properly (we achieved the 8th version of our hardware, which was the first device generation fully steady and consolidated). In paralel, we were able to develop our first external projects to be delivered to Europe and USA on demand, which consolidated our mastering over automation and control technologies.",

                time6: "12/2020 - 07/2021",
                title6: "APP RELEASED ON GOOGLE PLAY",
                description6: "WHOLE SYSTEM INTEGRATION AND OPEN APP RELEASED",
                text6: "It has been considered one of the most challenging period of our company. We decided not accept new external projects so that we could finish what was missing to launch our products within the real market. Also, we performed to whole integration among devices, app and cloud services. We we able to include essential features like manual commands being updated on the app screen and also devices signal status and devices connection updating (everything in real time). Our first open release had been introduced on Google Play store in 07/2021.",
                
                time7: "08/2021 – 12/2021",
                title7: "PRODUCTS CONSOLIDATION",
                description7: "IMPROVEMENTS, NEW OFFICE AND PRODUCT ENGINEERING BEGINNING",
                text7: "Some features were included in the app as an English version, inclusion of new members to control the same home, inclusion and updating of hubs in real time, register/visualization of users performed actions, factory mode (return) possibility for hubs, some corrections of devices status, etc. The open mode app release was launched in Apple Store in 11/2021. In addition, our new website was uploaded in early 12/2021 by includind English and Portuguese languages. Also, the 3/6 lamps switches and irrigation controllers were homologated and became to be part of our products porfolio for selling. Within this period, we developed the first enclosures for our products to be sold and started our office in CDN, Uberlandia.",

                time8: "01/2022 – 12/2022",
                title8: "SALES BEGINNING",
                description8: "APP STABILIZATION AND PRODUCTS READY FOR SELLING",
                text8: "Our customer sales started in 11/2022. About app/sytem improvements, we prioritized bugs fix and the app version (in Apple Store and Google Play) stabilization so that customers can have better user experience. In addition, new features have been included in our app as registering of hubs/devices trhough QR Code (without a needed serial manual typing), change of room selection menu and membership feature moved to the new drawer that was added in the last app release. Besides, we included the new user register confirmation through email link (auth0) and increased the operating capacity of users amount by using the system at the same time. On the hardware side, new devices have been integrated to our products portfolio: higher torque motors for bigger courtains/blinds/applications, water heaters with low energy consumption, touch screen controller, digital thermometers with temperature/humidity/air pressure measurement. We stabilized syncing between slave devices (switches, controls, etc.) and masters (motors, switches, controllers, etc.). The product engineering skill (enclosures) was established, increased and amplified for our final products usage.",

                time9: "01/2023 – 06/2023",
                title9: "B2B IN BRAZILIAN MARKET",
                description9: "B2B STRATEGY IN BRAZIL AND SALES INCREASING",
                text9: "After sales beginning, our priority is better support our consumers and increase sales by aligning that with production capacity. New features are being included in our app as scheduling of frequent routine actions, technical support by registering user's problems and trigger of commands/routines whenever a user gets closer to a determined distance from home. We are planning to establish partnership with new resellers in Brazil and increasing sales all over the territory. News about hardware are a release of LEDs with controlled brightness (wireless dimmer), a new hub directly connected to a carrier network (no WiFi needed) and updating the whole portfolio products for new versions.",

                time10: "07/2023 – 12/2023",
                title10: "INTERNATIONAL COMPANY",
                description10: "STARTING A NEW OPERATION ABROAD AND GLOBAL SALES",
                text10: "We are planning to launch a platform so that resellers can set up residences. In addition, we are going to release a fully integration for home appliances and releasing automobile hubs as an extension of wireless automation concepts for vehicles. We are also planning to launch an electric energy measurer, integrated to energy supply stations. We will be focused on getting started with an abroad operation in 07/2023 and increasing our sales in a global scale. We will restart to develop on demand projects as soon as we establish our office in another country.",
                
                titleXI: "News coming soon..."
            },
            contact: {
                title0: "Contact Us",
                text0: "We have all the following channels so that you can contact us and and solve any doubt you have.",
                
                title1: "Send a message:",
                text1: "Fill out the below data and send a message by clicking on the button \"Submit\".",
                
                userbox: "Username",
                mailbox: "Your email",
                sjtbox: "Subject",
                msgbox: "Your message",
                
                title2: "Direct contact:",
                text2: "If you wish, you can reach out through the following direct channels",
                
                email: "E-MAIL",
                phone: "BUSINESS PHONE",
                whatsapp: "WHATSAPP",
                address: "ADDRESS",
                time: "HORÁRIO",

                btnsend: "Submit",
                
                textWA: "Tap/click here for contacting us",
                linkWA: "https://wa.me/message/XRGKT7ALLS24H1"
            },
            policy: {
                title0: "PRIVACY POLICY (PORTUGUESE ONLY)",
                text00: "Este aplicativo é mantido e operado por Dragonfly IoT.",
                text01: "Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).",
                text02: "Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:",
                text03: "Quem deve utilizar nosso aplicativo;",
                text04: "Quais dados coletamos e o que fazemos com eles;",
                text05: "Seus direitos em relação aos seus dados pessoais; e",
                text06: "Como entrar em contato conosco.",
                
                title1: "1. Quem deve utilizar nosso aplicativo",
                text10: "Nosso aplicativo somente deve ser utilizado por pessoas que tenham, pelo menos, 4(quatro) anos de idade, sendo que a utilização por pessoa com menos de 18(dezoito) anos somente será possível mediante o consentimento de pelo menos um de seus pais ou responsável.",

                title2: "2. Dados que coletamos e motivos da coleta",
                text20: "Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.",
                option21: "1. Dados pessoais fornecidos expressamente pelo usuário",
                text21: "Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso aplicativo:",
                text22: "Nome;",
                text23: "Endereço de e-mail;",
                text24: "Número de telefone;",
                text25: "Endereço físico.",
                text26: "A coleta destes dados ocorre nos seguintes momentos:",
                text27: "Cadastro no aplicativo.",
                text28: "Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:",
                text29: "Para identificação do usuário na plataforma;",
                text210: "Para poder prestar suporte aos usuário dos nossos dispositivos.",
                
                option22: "2. Dados sensíveis",
                text211: "Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.",
                
                option23: "3. Coleta de dados não previstos expressamente",
                text212: "Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.",
                text213: "Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do aplicativo.",
                
                title3: "2. Dados que coletamos e motivos da coleta",
                text214: "Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.",
                
                title4: "4. Por quanto tempo seus dados pessoais serão armazenados",
                text215: "Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis.",
                text216: "Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.",

                title5: "5. Bases legais para o tratamento de dados pessoais",
                text217: "Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.",
                text218: "Todas as nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.",
                
                title6: "6. Direitos do usuário",
                text219: "O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:",
                text220: "confirmação da existência de tratamento;",
                text221: "acesso aos dados;",
                text222: "correção de dados incompletos, inexatos ou desatualizados;",
                text223: "anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;",
                text224: "portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;",
                text225: "eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;",
                text226: "informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;",
                text227: "informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;",
                text228: "revogação do consentimento.",
                text229: "É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados seja desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.",
                
                option61: "1. Como o titular pode exercer seus direitos",
                text230: "Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.",
                
                title7: "7. Medidas de segurança no tratamento de dados pessoais",
                text231: "Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.",
                text232: "As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.",
                text233: "Entre as medidas de segurança adotadas por nós, destacamos as seguintes:",
                text234: "Armazenamento de senhas utilizando hashes criptográficos;",
                text235: "Restrições de acessos a banco de dados;",
                text236: "Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.",
                text237: "De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.",
                
                title8: "8. Reclamação a uma autoridade de controle",
                text238: "Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.",
                
                title9: "9. Alterações nesta política",
                text239: "A presente versão desta Política de Privacidade foi atualizada pela última vez em: 23/07/2021.",
                text240: "Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.",
                text241: "Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.",
                
                title10: "10. Como entrar em contato conosco",
                text242: "Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:",
                text243: "E-mail: contato@iotdragonfly.com",
                text244: "Telefone: +55 34 3304-5775",
                text245: "Endereço postal: Av. Jaime Ribeiro da Luz, 971 - Sala 52 - Bairro Santa Mônica - Uberlândia - MG"
            }
        }
    }
}

export {messages}